<p-dialog
  styleClass="w-30rem overflow-hidden"
  [(visible)]="isVisible"
  [modal]="true"
  [draggable]="false"
  (onHide)="closeModal()"
>
  <p-header>
    <h2 class="font-light text-2xl">Provide Your Data Source</h2>
  </p-header>

  <div class="flex flex-column row-gap-3">
    <div *ngFor="let service of serviceDetails | keyvalue" class="border-1 border-outline p-3">
      <div class="flex align-items-start">
        <img class="w-3rem" [src]="service.value.logo" [alt]="service.value.title" />
        <div class="pl-3">
          <h4 class="text-base font-bold pb-2">{{ service.value.title }}</h4>
          <p class="text-xs">{{ service.value.description }}</p>
        </div>
      </div>
    </div>
  </div>

  <p-divider></p-divider>
  <p class="text-sm">In the next step, you can either provide an address, upload an XML, or upload a Blueprint.</p>
  <div class="flex justify-content-between mt-4">
    <div class="ml-auto">
      <button pButton (click)="closeModal()">Continue</button>
    </div>
  </div>
</p-dialog>
