<form *ngIf="addressGroup" [formGroup]="addressGroup">
  <div class="inputCont">
    <label for="street" class="label">Address</label>
    <input formControlName="street" pInputText id="street" type="text" class="w-full" placeholder="Address" />
    <label
      class="p-invalid"
      for="street"
      *ngIf="
        addressGroup.get('street')?.invalid &&
        (addressGroup.get('street')?.touched || addressGroup.get('street')?.dirty)
      "
    >
      <fa-icon [icon]="faTriangleExclamation"></fa-icon>
      Please enter an address</label
    >
  </div>
  <div class="inputCont">
    <label for="state" class="label">State/province</label>
    <p-dropdown
      [options]="stateList"
      optionLabel="name"
      class="w-full"
      placeholder="Select state"
      id="state"
      formControlName="state"
      optionValue="abbreviation"
    ></p-dropdown>
    <label
      class="p-invalid"
      for="state"
      *ngIf="
        addressGroup.get('state')?.invalid && (addressGroup.get('state')?.touched || addressGroup.get('state')?.dirty)
      "
    >
      <fa-icon [icon]="faTriangleExclamation"></fa-icon>
      Please enter a state</label
    >
  </div>
  <div class="inputCont">
    <label for="city" class="label">City</label>
    <input formControlName="city" pInputText id="city" type="text" class="w-full" placeholder="City" />
    <label
      class="p-invalid"
      for="city"
      *ngIf="
        addressGroup.get('city')?.invalid && (addressGroup.get('city')?.touched || addressGroup.get('city')?.dirty)
      "
    >
      <fa-icon [icon]="faTriangleExclamation"></fa-icon>
      Please enter a city
    </label>
  </div>
  <div class="inputCont">
    <label for="zip" class="label">ZIP code</label>
    <input formControlName="zip" pInputText id="zip" type="text" class="w-full max-w-10rem" placeholder="ZIP code" />
    <label
      class="p-invalid"
      for="zip"
      *ngIf="addressGroup.get('zip')?.invalid && (addressGroup.get('zip')?.touched || addressGroup.get('zip')?.dirty)"
    >
      <fa-icon [icon]="faTriangleExclamation"></fa-icon>
      Please enter a ZIP code
    </label>
  </div>
</form>
