import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AppConstants } from "src/app/shared/constants/app-constants";

const SERVICE_DETAILS = {
  EagleView: {
    title: "EagleView",
    description:
      "With only an address, you can obtain dimensions data from a location based on accurate aerial views provided by EagleView.",
    logo: "assets/img/icons/source_ev.png",
  },
  RoofingWRX: {
    title: "RoofingWRX",
    description:
      "Get accurate 3D models and measurements from any type of drawing, like architect drawings, napkin sketches, and more.",
    logo: "assets/img/icons/source_rw.png",
  },
  SmartBuild: {
    title: "SmartBuild",
    description:
      "Based on records of entire manufacturer catalogs, SmartBuild takes raw data from EagleView and RoofingWRX to accurately estimate material and labor costs.",
    logo: "assets/img/icons/source_sb.png",
  },
};

@Component({
  selector: "app-dialog-providers-step",
  templateUrl: "./dialog-providers-step.component.html",
  styleUrls: ["./dialog-providers-step.component.scss"],
})
export class DialogProvidersStepComponent {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  serviceDetails = SERVICE_DETAILS;

  closeModal = () => {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  };

  constructor() {}
}
