<header #mainHeader class="mainHeader">
  <nav class="mainHeaderNav">
    <div class="headerTopCont">
      <button (click)="mainMenuMobile.toggle($event)" class="headerBtn headerBtn_icon mobileOnly">
        <fa-icon [icon]="faBars"></fa-icon>
      </button>
      <p-overlayPanel #mainMenuMobile [appendTo]="mainHeader" styleClass="topUnset">
        <ng-template pTemplate>
          <ul class="dropdownMenu">
            <li (click)="clearFiltersAndNavigate()">
              <a class="dropdownButton">
                <span>Job List</span>
              </a>
            </li>
          </ul>
        </ng-template>
      </p-overlayPanel>
      <h1 class="headerTitle" *ngIf="!(isSelectingCompany | async) && !(isSettingUpAccount | async)">
        <a routerLink="../jobs" class="logoLink">
          <img src="assets/img/logo/rfLogo.svg" alt="Roofing Passport" class="headerLogo" />
          <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="headerLogo headerLogo_mobile" />
        </a>
      </h1>
      <h1 class="headerTitle" *ngIf="(isSelectingCompany | async) || (isSettingUpAccount | async)">
        <div class="logoLink">
          <img src="assets/img/logo/rfLogo.svg" alt="Roofing Passport" class="headerLogo" />
          <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="headerLogo headerLogo_mobile" />
        </div>
      </h1>
      <ul class="headerMenu headerMenu_main" *ngIf="!(isSelectingCompany | async) && !(isSettingUpAccount | async)">
        <li (click)="clearFiltersAndNavigate()">
          <a class="headerBtn headerBtn_link">Job list</a>
        </li>
      </ul>
    </div>
    <div class="headerEndCont" *ngIf="!(isSelectingCompany | async) && !(isSettingUpAccount | async)">
      <ul class="headerMenu">
        <li *ngIf="canViewAdminPanel">
          <button (click)="redirectToAdminPanel()" class="headerBtn headerBtn_icon" title="Administration">
            <fa-icon [icon]="faGear"></fa-icon>
          </button>
        </li>
        <li>
          <button (click)="accountMenu.toggle($event)" class="headerBtn headerBtn_account">
            <span class="headerBtn__accountLabelCont">
              <fa-icon [icon]="faCircleUser" class="headerBtn__accountIcon"></fa-icon>
              <span class="headerBtn__accountLabel">
                <span class="headerBtn__accountLabel__name">{{ fullName | async }}</span>
                <span *ngIf="profile" class="headerBtn__accountLabel__company">{{ profile?.manufacturer?.name }}</span>
              </span>
              <span class="srOnly">Account options</span>
            </span>
            <fa-icon [icon]="faChevronDown" class="headerBtn__dropdownIcon"></fa-icon>
          </button>
          <p-overlayPanel #accountMenu [appendTo]="mainHeader" styleClass="topUnset">
            <ng-template pTemplate>
              <div class="dropdownHeader dropdownProfileHeader">
                <img
                  src="assets/img/placeholder/userProfilePicPlaceholder.png"
                  alt="Profile picture"
                  class="dropdownProfileHeader__profileImg"
                />
                <div class="dropdownProfileHeader__titleCont">
                  <span class="dropdownProfileHeader__title">{{ fullName | async }}</span>
                  <span *ngIf="profile" class="dropdownProfileHeader__subtitle">{{ profile.manufacturer.name }}</span>
                </div>
              </div>
              <ul class="dropdownMenu marginBottom">
                <li *ngIf="profiles.length > 1">
                  <a routerLink="../select-company" class="dropdownButton">
                    <fa-icon [icon]="faBuilding" class="dropdownButton__icon"></fa-icon>
                    <span>Change company</span>
                  </a>
                </li>
                <li>
                  <a routerLink="account-settings" class="dropdownButton">
                    <fa-icon [icon]="faCog" class="dropdownButton__icon"></fa-icon>
                    <span>Account settings</span>
                  </a>
                  <span class="divider"></span>
                </li>
                <li>
                  <a routerLink="help" class="dropdownButton">
                    <fa-icon [icon]="faQuestionCircle" class="dropdownButton__icon"></fa-icon>
                    <span>Help & support</span>
                  </a>
                  <span class="divider"></span>
                </li>
                <li>
                  <button (click)="logoutHandler()" class="dropdownButton danger">
                    <fa-icon [icon]="faSignOut" class="dropdownButton__icon"></fa-icon>
                    <span>Sign out</span>
                  </button>
                </li>
              </ul>
            </ng-template>
          </p-overlayPanel>
        </li>
      </ul>
    </div>
  </nav>
</header>
