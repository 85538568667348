<p-header>
  <h2 class="font-light text-2xl">Obtaining dimensions data</h2>
</p-header>
<p class="text-sm mt-3 mb-4">Visualize how we create your takeoff report, step-by-step:</p>

<div class="flex flex-column align-items-center mb-5">
  <div
    class="border-1 py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter animated-line relative"
    [ngClass]="{
      'border-main': currentStep === 2,
      'border-outline': currentStep !== 2
    }"
  >
    <fa-icon size="lg" class="text-main" [icon]="faCircleUser"></fa-icon>
    <span class="text-sm ml-4">You</span>
    <fa-icon *ngIf="currentStep === 2" [icon]="faPaperPlane" class="icon bottomIcon"></fa-icon>
  </div>
  <div
    class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold relative lineAfter border-main"
    [ngClass]="{
      'animated-line': currentStep === 2
    }"
  >
    <fa-icon *ngIf="currentStep === 1" [icon]="faPaperPlane" class="icon topIcon"></fa-icon>
    <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-2rem" />
    <span class="text-sm ml-4">Roofing Passport</span>
  </div>
  <div
    class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold relative"
    [ngClass]="{
      'border-main': currentStep === 2,
      'border-outline': currentStep !== 2
    }"
  >
    <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="w-2rem" />
    <span class="text-sm ml-4">SmartBuild</span>
  </div>
</div>

<p-divider></p-divider>
<h4 class="my-3 font-semibold text-xs">Step {{ currentStep }}</h4>
<p class="text-sm" [ngSwitch]="currentStep">
  <ng-container *ngSwitchCase="1">
    Once you confirm the new job, the <span class="font-semibold">XML</span> you upload is validated and accepted by
    Roofing Passport and then sent to <span class="font-semibold">SmartBuild</span>.
  </ng-container>
  <ng-container *ngSwitchCase="2">
    XML data is sent to <span class="font-semibold">SmartBuild</span> and converted into 3D models and roofing
    measurements. SmartBuild notifies Roofing Passport that the job is complete. You can retrieve your takeoff report
    within Roofing Passport or directly through SmartBuild.
  </ng-container>
</p>
<div class="flex justify-content-between mt-4">
  <button *ngIf="currentStep > 1" pButton class="default outline text-main border-main" (click)="previousStep()">
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    Back
  </button>
  <div class="ml-auto">
    <button *ngIf="currentStep === 1" pButton class="default outline mr-3 text-main border-main" (click)="skip()">
      Skip
    </button>
    <button pButton (click)="nextStep()">
      {{ currentStep === 2 ? "Continue" : "Next" }}
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</div>
