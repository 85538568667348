import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrderCountsByCompanyUser } from 'src/app/core/data/models/OrderCountsByCompanyUser';
import { ReportingService } from 'src/app/shared/services/reporting.service';

@Component({
  selector: 'app-kpi-company-user',
  templateUrl: './kpi-company-user.component.html',
  styleUrls: ['./kpi-company-user.component.scss']
})
export class KpiCompanyUserComponent implements OnInit {

  @Output() listCompanyByUserEmit = new EventEmitter();

  constructor(private repotingService: ReportingService) { }

  ngOnInit(): void {
    const today = new Date();
    this.startDate.setMonth(this.startDate.getMonth() - 1);
    this.maxDate = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());
    this.GetOrderCountsByCompanyByUser();
  }
  listByCompany: any[] = [];  
  listByCompanyByUser: OrderCountsByCompanyUser[] = [];
  displayedColumnsByCompany: string[] = [];
  displayedColumnsByCompanyByUser: string[] = [];
  startDate: Date = new Date();
  
  endDate: Date = new Date();
  maxDate: Date = new Date();
  minDate: Date = new Date();
  isLoading: boolean = false;

  GetOrderCountsByCompanyByUser() {
    this.repotingService.GetOrderCountsByCompanyByUser(this.startDate, this.endDate).subscribe((data: OrderCountsByCompanyUser[]) => {
      this.listByCompanyByUser = this.transformData(data);
      const dynamicColumns = Object.keys(this.listByCompanyByUser[0]);
      this.displayedColumnsByCompanyByUser = [...this.displayedColumnsByCompanyByUser, ...dynamicColumns.slice(9)];
      this.listCompanyByUserEmit.emit({ data: this.listByCompanyByUser, columns: this.displayedColumnsByCompanyByUser });
    });
  }

  applyDateFilter() {
    this.startDate = new Date(this.startDate);
    this.endDate = new Date(this.endDate);
    this.cleanData();
    this.GetOrderCountsByCompanyByUser();

  }

  cleanData() {
    this.listByCompany = [];
    this.listByCompanyByUser = [];
    this.displayedColumnsByCompany = [];
    this.displayedColumnsByCompanyByUser = [];
  }

  transformData(data: any[]) {
    return data.map(item => {
      const dynamicRows = item.dynamicRows;
      delete item.dynamicRows;
      delete item.results;
      delete item.manufacturarName;
      return { ...item, ...dynamicRows };
    });
  }
  onDateChange(event: any) {
    this.minDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + 1);
  }
}
