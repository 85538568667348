import {RouterModule, Routes} from "@angular/router";
import {AdminSettingsComponent} from "./admin-settings/admin-settings.component";
import {AdminUsersComponent} from "./admin-users/admin-users.component";
import {NgModule} from "@angular/core";
import {AdminComponent} from "./admin.component";
import {AdminReportingComponent} from "./admin-reporting/admin-reporting.component";
import {WorkQueueComponent} from "./work-queue/work-queue.component";
import {EditUserComponent} from "./admin-users/edit-user/edit-user.component";
import {EditCompanyComponent} from "./edit-company/edit-company.component";
import {InviteUserComponent} from "./invite-user/invite-user.component";
import {CompaniesListComponent} from "./companies-list/companies-list.component";
import {SetupGuard} from "../../setup.guard";
import {AdminGuard} from "../../admin.guard";


const AdminRoutes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AdminGuard, SetupGuard],
    children: [
      {
        path: "work-queue",
        component: WorkQueueComponent,
      },
      {
        path: "manufacturer-settings",
        title: "Company - Administration - Roofing Passport",
        component: AdminSettingsComponent,
      },
      {
        path: "users",
        title: "Users - Administration - Roofing Passport",
        component: AdminUsersComponent,
      },
      {
        path: "invite-user",
        title: "Invite user - Administration - Roofing Passport",
        component: InviteUserComponent,
      },
      {
        path: "edit-user/:id",
        title: "Edit user - Administration - Roofing Passport",
        component: EditUserComponent,
      },
      {
        path: "companies",
        title: "Companies - Administration - Roofing Passport",
        component: CompaniesListComponent,
      },
      {
        path: "company",
        title: "Company - Administration - Roofing Passport",
        component: EditCompanyComponent,
      },
      {
        path: "edit-company/:id",
        title: "Company - Administration - Roofing Passport",
        component: EditCompanyComponent,
      },
      {
        path: "reporting",
        title: "Reports - Administration - Roofing Passport",
        component: AdminReportingComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(AdminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {
}
