import {ServiceStatus} from "../data/models/AppInitializationData";
import {AppConstants} from "src/app/shared/constants/app-constants";
import {ThirdPartyAuth} from "../store/app.models";

interface ServiceDetail {
  learnMoreLink?: string;
  title: string;
  description: string;
  disclaimer?: string;
  logo: string;
}

export type Service = ServiceDetail & ServiceStatus & ThirdPartyAuth;

export const SERVICE_DETAILS: Record<string, ServiceDetail> = {
  EagleView: {
    title: AppConstants.ThirdPartyServiceName.EagleView,
    description:
      "With only an address, you can obtain dimensions data from a location based on accurate aerial views provided by EagleView.",
    disclaimer: "At this time, EagleView provides data for buildings in the United States and Canada only.",
    logo: "assets/img/icons/source_ev.png",
  },
  RoofingWRX: {
    title: AppConstants.ThirdPartyServiceName.RoofingWRX,
    description:
      "Get accurate 3D models and measurements from any type of drawing, like architect drawings, napkin sketches, and more.",
    logo: "assets/img/icons/source_rw.png",
  },
  SmartBuild: {
    title: AppConstants.ThirdPartyServiceName.SmartBuild,
    description:
      "Based on records of entire manufacturer catalogs, SmartBuild takes raw data from EagleView and RoofingWRX to accurately estimate material and labor costs.",
    logo: "assets/img/icons/source_sb.png",
  },
};
