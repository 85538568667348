<div class="container_fluid">
  <div class="pageHeading">
    <div class="flex align-items-center gap-4">
      <h2 class="title2">Profile</h2>
    </div>
  </div>
  <div class="grid my-0 flex-column-reverse lg:flex-row">
    <div class="col-12 lg:col-8 xl:col-9">
      <form action="#" [formGroup]="userForm">
        <div class="grid my-0">
          <div class="col-12 md:col-6 xl:col-4 py-0">
            <div class="inputCont">
              <label for="profileInput_firstName" class="label">First name</label>
              <input
                type="text"
                id="profileInput_firstName"
                name="firstName"
                placeholder="First name"
                class="w-full"
                pInputText
                formControlName="firstName"
              />
            </div>
          </div>
          <div class="col-12 xl:col-4 py-0">
            <div class="inputCont">
              <label for="profileInput_lastName" class="label">Last name</label>
              <input
                type="text"
                id="profileInput_lastName"
                name="lastName"
                placeholder="Last name"
                class="w-full"
                pInputText
                formControlName="lastName"
              />
            </div>
          </div>
        </div>
        <div class="grid my-0">
          <div class="col-12 xl:col-8 py-0">
            <div class="inputCont">
              <label for="profileInput_email" class="label">Email</label>
              <input
                type="email"
                id="profileInput_email"
                name="email"
                placeholder="Email"
                class="w-full"
                pInputText
                formControlName="email"
                [readonly]="true"
                disabled
              />
              <p class="inputDescription text-light">
                This email is used when signing in and as the destination for notifications and important messages,
                including password resets. Please make sure this is a valid address you have access to.
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6 xl:col-4 py-0">
            <div class="inputCont">
              <label for="profileInput_phone" class="label">Phone</label>
              <input
                type="tel"
                id="profileInput_phone"
                name="phone"
                placeholder="Phone"
                class="w-full"
                pInputText
                formControlName="phoneNumber"
              />
            </div>
          </div>
          <div class="col-12 xl:col-8 py-0"></div>
        </div>
        <div class="mt-2 flex flex-column md:flex-row align-items-center gap-4">
          <button (click)="updateUser()" pButton class="fullMobile" type="button" [disabled]="isDisabled()">
            <fa-icon [icon]="faSave"></fa-icon>
            Update profile
          </button>
          <p *ngIf="!isDisabled()" class="text-sm font-semibold text-light">Unsaved changes</p>
        </div>
      </form>
    </div>
  </div>
</div>
