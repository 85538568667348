import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DEMO_FEATURE_FLAGS } from "../../../shared/constants/feature-flags";

@Component({
  selector: "app-intro-modal",
  templateUrl: "./intro-modal.component.html",
  styleUrls: ["./intro-modal.component.scss"],
})
export class IntroModalComponent implements OnInit {
  isVisible: boolean = false;
  faInfoCircle = faInfoCircle;
  email: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  open(email: string) {
    this.isVisible = true;
    this.email = email;
  }

  startNewJobDemo() {
    this.isVisible = false;
    const queryParams = DEMO_FEATURE_FLAGS.REQUEST_EMAIL ? { email: this.email } : {};
    this.router.navigate(["/demo/create-job"], { queryParams });
  }
}
