import { Component } from "@angular/core";
import { faFloppyDisk, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { OpenIdConfiguration } from "angular-auth-oidc-client";
import { ConfirmService } from "src/app/core/confirm/confirm.service";
import { ThirdPartyAuth, UserRole } from "src/app/core/store/app.models";
import { Service, SERVICE_DETAILS } from "src/app/core/third-party-integrations/service-details";
import { AppState } from "src/app/shared/services/app-state";
import { AuthContext, AuthenticationService } from "src/app/shared/services/authentication.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthStatus } from "../../../core/data/models/AppInitializationData";

@Component({
  selector: "app-account-settings-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss"],
})
export class IntegrationsComponent {
  faQuestion = faQuestion;
  faFloppyDisk = faFloppyDisk;
  userRoles = [];
  roles = UserRole;
  serviceStatus = this.appState.serviceStatus;
  services: Service[] = [];
  private _unlinkAllAccounts: BehaviorSubject<boolean> = new BehaviorSubject(true);
  unlinkAllAccounts: Observable<boolean> = this._unlinkAllAccounts.asObservable();

  constructor(
    private appState: AppState,
    private authService: AuthenticationService,
    private confirmService: ConfirmService,
    private profileService: ProfileService
  ) {
    this.appState.appData$.subscribe({
      next: (appData) => {
        this.userRoles = appData.user.roles;
      },
    });
  }

  mapServices(): void {
    const unlink = this.appState.authorizations.find((s) => s.status !== AuthStatus.None) !== undefined;
    this._unlinkAllAccounts.next(unlink);

    this.services = this.serviceStatus.map((service) => {
      const enabled = this.appState.enabledPartnerSystems[service.service];
      if (!enabled) {
        return null;
      }

      const details = SERVICE_DETAILS[service.service];
      const auth: ThirdPartyAuth = this.appState.authorizations.find(
        (p) => p.context.toString() == service.service.toString()
      );
      return {
        ...service,
        ...details,
        ...auth,
      };
    });
  }

  hasEnabledServices(): boolean {
    return this.services.some((s) => s !== null);
  }

  ngOnInit(): void {
    this.mapServices();
  }

  logoutAll() {
    const toClear: AuthContext[] = [];
    const toLogout: OpenIdConfiguration[] = [];
    const contexts: OpenIdConfiguration[] = this.authService.getContexts(false);

    const toUnlink = this.appState.authorizations.filter((s) => s.status !== AuthStatus.None).map((s) => s.context);

    contexts.forEach((context) => {
      const auth = AuthContext[context.configId];
      if (toUnlink.includes(auth)) {
        toClear.push(AuthContext[context.configId]);
        toLogout.push(context);
      }
    });

    if (toClear.length === 0) {
      return;
    }

    this.confirmService.confirm(
      "Logout",
      `Are you sure you want to logout of all systems?`,
      "pi pi-question-circle",
      () => {
        this.profileService.clearTokens(toClear).subscribe({
          next: () => {
            this.authService.logoutAll(toLogout, { urlHandler: () => {} });
            this.mapServices();
          },
        });
      },
      () => {
        this.confirmService.close();
      }
    );
  }
}
