<p-header>
  <h2 class="font-light text-2xl">Processing jobs</h2>
</p-header>
<p class="text-sm mt-3 mb-4">
  This is a quick recap of what happens behind the scenes since the moment you create a new job:
</p>

<div class="relative flex flex-column align-items-center">
  <div
    class="border-1 border-outline py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter animated-line"
    [ngClass]="{
      'border-main': currentStep === 2,
      'border-outline': currentStep !== 2
    }"
  >
    <fa-icon size="lg" class="text-main" [icon]="faCircleUser"></fa-icon>
    <span class="text-sm ml-4">You</span>
    <button *ngIf="currentStep === 1" pButton class="pointer-events-none bg-success border-success ml-4">
      <fa-icon [icon]="faCheck"></fa-icon>
      Create job
    </button>
  </div>
  <div
    class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold relative border-main lineAfter"
    [ngClass]="{
      'animated-line': currentStep === 2
    }"
  >
    <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-2rem" />
    <span class="text-sm ml-4">Roofing Passport</span>
  </div>
  <div
    class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold relative"
    [ngClass]="{
      'border-main': currentStep === 2,
      'border-outline': currentStep !== 2
    }"
  >
    <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="w-2rem" />
    <span class="text-sm ml-4">SmartBuild</span>
  </div>
</div>

<p-divider></p-divider>
<h4 class="my-3 font-semibold text-xs">Step {{ currentStep }}</h4>
<p class="text-sm" [ngSwitch]="currentStep">
  <ng-container *ngSwitchCase="1">
    Once you hit ”Create job”, Roofing Passport will validate the XML you uploaded, and then send the file off to
    SmartBuild.
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <p>
      XML data is sent to SmartBuild and converted into 3D models and roofing measurements. SmartBuild notifies Roofing
      Passport that the job is complete. You can retrieve your takeoff report within Roofing Passport or directly
      through SmartBuild.
    </p>
    <p class="mt-2">
      Please note that this process and its duration varies depending of the data source and providers you choose.
    </p>
  </ng-container>
</p>
<div class="flex justify-content-between mt-4">
  <button *ngIf="currentStep > 1" pButton class="default outline text-main border-main" (click)="previousStep()">
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    Back
  </button>
  <div class="ml-auto">
    <button *ngIf="currentStep !== 2" pButton class="default outline mr-3 text-main border-main" (click)="skip()">
      Skip
    </button>
    <button pButton (click)="nextStep()">
      {{ currentStep === 2 ? "Continue" : "Next" }}
      <fa-icon *ngIf="currentStep !== 2" [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</div>
