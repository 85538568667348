import { Component } from "@angular/core";
import { MenuItem } from "primeng/api";
import { faUser, faLock, faBuilding, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
})
export class AccountSettingsComponent {
  faUser = faUser;
  faLock = faLock;
  faBuilding = faBuilding;
  faPuzzlePiece = faPuzzlePiece;

  adminMenuItems: MenuItem[] = [
    { label: "Profile", routerLink: "./profile", iconStyle: faUser },
    // { label: "Security and Password", routerLink: "./security", iconStyle: faLock },
    { label: "Companies", routerLink: "./companies", iconStyle: faBuilding },
    { label: "Integrations", routerLink: "./integrations", iconStyle: faPuzzlePiece },
  ];

  constructor() {}
}
