<div class="container" *ngIf="isVisible">
  <div class="pageHeading pageHeading_center columnMobileOnly">
    <h1 class="pageTitle pageTitle_steps title2 my-0">Create new job</h1>
  </div>
  <section class="card card_border card_border_noMobile">
    <div class="text-center mb-5">
      <div class="messageCircle danger mb-3 mt-6">
        <fa-icon [icon]="faXmark"></fa-icon>
      </div>
      <h3 class="title2 mb-3">Job creation failed</h3>
      <p class="textWidthLimit">Sorry, we were unable to create your job.</p>
    </div>
    <section class="footActionsCont spaced">
      <div class="footActionsCont">
        <button (click)="hideAndNavigate()" pButton class="outline main fullMobile">
          <fa-icon [icon]="faPlus"></fa-icon> Create a new job
        </button>
      </div>
      <a [routerLink]="['/jobs']" pButton class="outline default fullMobile"> Go back to jobs list </a>
    </section>
  </section>
</div>
