<div class="flex flex-column">
  <h2 class="title4 pb-3">Roofing Passport Terms of Service</h2>
  <div class="termsCont overflow-y-auto border-1 border-outline p-3 text-xs">
    <p>Version Effective: June 1, 2022</p>

    <p>
      <b
        >PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY CLICKING &#8220;I AGREE,&#8221; USER (AS HEREINAFTER
        DEFINED) AGREES TO THESE TERMS AND CONDITIONS. CONTINUED USE OF THE APPLICATION (AS HEREINAFTER DEFINED) AFTER
        WE HAVE NOTIFIED USER OF CHANGES TO THESE TERMS CONSTITUTES USER&#8217;S ACCEPTANCE OF THE TERMS.</b
      >
    </p>
    <p>
      THESE ROOFING PASSPORT TERMS OF SERVICE, ALONG WITH THE SHERWIN-WILLIAMS TERMS OF USE, CONSTITUTE AN AGREEMENT
      (&#8220;AGREEMENT&#8221;) BETWEEN USER (&#8220;USER&#8221; OR &#8220;YOU&#8221;) AND THE SHERWIN-WILLIAMS COMPANY
      AND ITS AFFILIATES (&#8220;SHERWIN-WILLIAMS&#8221;), AND GOVERN YOUR USE OF THE SHERWIN-WILLIAMS ROOFING PASSPORT
      APPLICATION (&#8220;APPLICATION&#8221;). IF YOU ARE USING THE APPLICATION ON BEHALF OF A COMPANY OR OTHER ENTITY,
      THEN &#8220;YOU&#8221; OR &#8220;USER&#8221; MEANS THAT ENTITY, AND YOU ARE BINDING THAT ENTITY TO THIS AGREEMENT.
    </p>

    <p>
      BY CLICKING ON THE &#8220;I AGREE&#8221; BUTTON, OR USING THE APPLICATION, YOU REPRESENT THAT: (1) IF YOU ARE
      ACCEPTING THIS AGREEMENT ON BEHALF OF AN ENTITY THAT YOU HAVE AUTHORITY TO BIND THE ENTITY AND ENTER INTO THIS
      AGREEMENT ON THE ENTITY&#8217;S BEHALF, (2) THAT YOU UNDERSTAND ALL THE TERMS OF THIS AGREEMENT, AND (3) THAT YOU
      ARE CONSENTING TO BE BOUND BY THIS AGREEMENT.
    </p>

    <p>
      <strong><u>Roofing Passport Application.</u></strong>
      The Application is a platform that allows Users to: (i) request property measurement reports from EagleView
      Technologies, Inc. (&#8220;EagleView&#8221;), (ii) request property measurement reports from Roofing WRX, LLC
      (&#8220;Roofing WRX&#8221;) and (iii) connect to the SmartBuild Systems web-based software
      (&#8220;SmartBuild&#8221;) from Keymark Enterprises, LLC (&#8220;Keymark&#8221;) in order to view and edit the
      data contained in the EagleView property measurement reports or Roofing WRX property measurement reports. In order
      for the Application to function as intended, User must have separate accounts with EagleView and/or Roofing WRX,
      and Keymark, and these accounts must be active and in good standing. User acknowledges that User&#8217;s use of
      the data, reports, software, features, and functions provided by EagleView, Roofing WRX and/or Keymark are
      governed by the terms of User&#8217;s agreements with EagleView, Roofing WRX and/or Keymark.
      <strong
        >In particular, User understands that User will be charged for each EagleView and/or Roofing WRX report
        requested via the Application in accordance with the terms of its agreement with EagleView and/or Roofing
        WRX.</strong
      >
      Any warranties or remedies that may be available to User for the EagleView, Roofing WRX or Keymark services are as
      set forth in User&#8217;s agreements with EagleView, Roofing WRX and Keymark.
    </p>

    <p>
      <strong><u>EagleView, Roofing WRX and Keymark.</u></strong>
      User acknowledges that Sherwin-Williams does not provide, and has no control over, the EagleView property
      measurement reports, Roofing WRX property measurement reports, any data contained in such reports, or the
      SmartBuild software. Neither EagleView, Roofing WRX nor Keymark are affiliated with Sherwin-Williams.
      Sherwin-Williams has no control over and is not responsible for the accuracy, performance, functionality, or
      security of any services, applications, data, reports, or software provided by EagleView, Roofing WRX or Keymark.
      This includes, but is not limited, the accuracy of any EagleView or Roofing WRX property measurement reports
      provided via the Application.
    </p>

    <p>
      <strong><u>License.</u></strong>
      Subject to User&#8217;s compliance with this Agreement, Sherwin-Williams hereby grants to User a limited,
      non-exclusive license to use the Application to request property measurement reports from EagleView and Roofing
      WRX and connect to User&#8217;s SmartBuild account. Except for the rights expressly granted herein, no other
      rights are granted to User under this Agreement, and all rights not expressly granted herein are reserved by
      Sherwin-Williams. User acknowledges and agrees that: (i) the Application is protected under U.S. and foreign
      copyright and other intellectual property laws; (ii) Sherwin-Williams and its licensors retain all copyrights and
      other intellectual property rights in the Application; and (iii) except as otherwise provided herein, User
      acquires no ownership in or to the Application. User acknowledges that Sherwin-Williams may change or discontinue
      any of the features of the Application (or the Application as a whole) from time to time, in its sole discretion.
    </p>

    <p>
      <strong><u>Restrictions on Use.</u></strong>
      Use of the Application is restricted to the User. User shall remain fully responsible for the actions of any
      person using User&#8217;s log-in credentials, whether authorized or unauthorized by User. User shall take
      reasonable steps to prevent unauthorized access to the Application. User shall not: (i) resell, rent, lend, lease,
      distribute, or timeshare the Application or otherwise use the Application on behalf of any third party (including
      on a &#8220;service bureau&#8221; or similar basis), or otherwise provide third parties with access to the
      Application other than as expressly permitted by Sherwin-Williams; (ii) alter or remove any marks, proprietary
      legends, or privacy statements contained in the Application; (iii) circumvent or otherwise interfere with any
      authentication or security measures of the Application; (iv) interfere with or disrupt the integrity or
      performance of the Application; (v) access all or any portion of the Application by means of any crawler, scraper,
      bot, spider, or any other similar script or automated process; (vii) transmit material containing software viruses
      or other harmful computer code, files, scripts, agents, or programs; or (viii) reverse engineer, decompile,
      disassemble or otherwise attempt to discover the object code, source code or underlying ideas or algorithms of the
      Application. The Application may only be used for lawful purposes and in a lawful manner, in furtherance of
      User&#8217;s business purposes and for the purpose for which it was designed and intended. User agrees to comply
      with all applicable laws, statutes and regulations.
    </p>

    <p>
      <strong><u>Termination.</u></strong>
      Sherwin-Williams may terminate User&#8217;s access to the Application at any time and for any reason, including,
      but not limited to, User&#8217;s violation of this Agreement or User&#8217;s violation of User&#8217;s EagleView,
      Roofing WRX or Keymark agreements. In particular, User acknowledges that User&#8217;s access to the Application
      requires authorization by a company whose library of materials User accesses via the Application. Should such
      company withdraw its authorization, or should such authorization no longer be valid under the terms of the
      company&#8217;s agreement with Sherwin-Williams, User&#8217;s access to the Application would cease.
      <br /><br />
      If User is a company or contractor, User acknowledges that User&#8217;s access to the Application requires that
      User shall use Sherwin-Williams as its exclusive supplier and purchase one hundred percent (100%) of User&#8217;s
      coil coatings from Sherwin-Williams for as long as User utilizes the Application. In the event that User fails to
      purchase one hundred percent (100%) of User&#8217;s coil coatings from Sherwin-Williams, User&#8217;s access to
      the Application will immediately cease.
    </p>

    <p>
      <strong><u>Confidentiality.</u></strong>
      For purposes of this Agreement, the term &#8220;Confidential Information&#8221; shall mean all aspects of the
      Application, including information relating to this Agreement but not limited to any development plans, training
      materials, screenshots, and documentations. User agrees to hold all Confidential Information in strict confidence,
      use it only in conjunction with use of the Application authorized by this Agreement, and exercise its best efforts
      to prevent a disclosure of such Confidential Information.
    </p>

    <p>
      <strong><u>Disclaimer of Warranties.</u></strong>
      THE APPLICATION IS PROVIDED ‘AS-IS.&#8217; TO THE MAXIMUM EXTENT PERMITTED BY LAW, SHERWIN-WILLIAMS DISCLAIMS ALL
      EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND TITLE. SHERWIN-WILLIAMS DOES NOT WARRANT
      THE TIMELINESS, SUITABILITY, OR ACCURACY OF ANY RESULTS THAT USER MAY OBTAIN FROM USE OF THE APPLICATION.
      SHERWIN-WILLIAMS DOES NOT WARRANT UNINTERRUPTED OR ERROR-FREE OPERATION OF THE APPLICATION, THAT THE APPLICATION
      WILL BE FREE OF VIRUSES, BE INVULERABLE TO DISRUPTION, INTRUSION, ATTACK, OR THAT SHERWIN-WILLIAMS WILL CORRECT
      ALL DEFECTS. SHERWIN-WILLIAMS SHALL NOT BE LIABLE TO USER OR TO ANY THIRD PARTY, AND ASSUMES NO RESPONSIBILITY,
      FOR ANY LOSS OR DAMAGE RESULTING FROM OR RELATING TO (I) USER&#8217;S USE OF ANY REPORTS OR RESULTS OBTAINED FROM
      THE APPLICATION; OR (II) ANY ACT OR OMISSION BY EAGLEVIEW, ROOFING WRX, KEYMARK, OR ANY OTHER THIRD PARTY. USER IS
      SOLELY RESPONSIBLE FOR ITS USE OF THE APPLICATION, INCLUDING BUT NOT LIMITED TO ANY USE IT MAKES OF ANY DATA OR
      REPORTS OBTAINED AS RESULT OF USE OF THE APPLICATION.
    </p>

    <p>
      <strong><u>Limitation of Liability.</u></strong>
      IN NO EVENT SHALL SHERWIN-WILLIAMS, OR ITS DIRECTORS, OFFICERS, EMPLOYEES OR AGENT BE LIABLE FOR ANY LOSS OF
      PROFITS, LOSS OF USE, BUSINESS INTERRUPTION, LOSS OF DATA, COST OF COVER, OR FOR ANY OTHER SPECIAL, INDIRECT,
      INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND IN ANY WAY CONNECTED TO OR ARISING OUT OF THE
      FURNISHING, PERFORMANCE, OR USE OF THE APPLICATIONS, OR THIS AGREEMENT. Sherwin-Williams&#8217; maximum aggregate
      liability for damages or loss, howsoever arising or caused, shall in no event exceed five hundred U.S. dollars
      ($500). The limitations of this Section apply: (i) to liability for negligence, (ii) regardless of the form of
      action, whether in contract, tort, strict product liability, or otherwise, (ii) even if User is advised in advance
      of the possibility of the damages in question and even if such damages were foreseeable, and (iv) even if
      User&#8217;s remedies fail of their essential purpose. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
      INCIDENTAL OR CONSEQUENTIAL DAMAGES SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IF ANY APPLICABLE
      AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, THEN LIABILITY WILL BE LIMITED TO THE FULLEST
      EXTENT PERMITTED BY APPLICABLE LAW.
    </p>

    <p>
      <strong><u>Sherwin-Williams Terms of Use.</u></strong>
      The Sherwin-Williams Terms of Use also apply to User&#8217;s use of the Application, including, but not limited
      to, provisions addressing indemnity, choice of law, and dispute resolution. The Application is a
      &#8220;Website&#8221; pursuant to the Terms of Use. These Roofing Passport Terms of Service, together with the
      Sherwin-Williams Terms of Use, form the entire agreement between User and Sherwin-Williams relating to the
      Application. In the event of any conflict between the Roofing Passport Terms of Service and the Sherwin-Williams
      Terms of Use, the Roofing Passport Terms of Service shall apply.
    </p>

    <p>
      <strong><u>Notices.</u></strong>
      Any communication between Sherwin-Williams and User relating to this Agreement shall be in writing and shall be
      sent by reputable overnight courier or by certified mail, return receipt requested, to, in the case of the User,
      the contact person listed for User during the Application&#8217;s registration process, and in the case of
      Sherwin-Williams, to General Counsel, 101 W. Prospect Avenue, Cleveland, Ohio 44115. Either party may change the
      address for notice by sending a notice in the manner provided herein.
    </p>

    <p>
      <strong><u>Miscellaneous.</u></strong>
      No waiver of either party&#8217;s rights under this Agreement shall be effective unless made in a writing signed
      by an authorized officer of Sherwin-Williams and signed on behalf of User. The waiver of a breach of any provision
      of this Agreement shall not constitute a waiver of a prior, concurrent or subsequent breach of the same provision
      or of any other provision. This Agreement shall be governed by Ohio law. Any claim, dispute or litigation arising
      directly or indirectly in connection with this Agreement shall be decided solely and exclusively by a state or
      federal court located in Cuyahoga County, Ohio. In the event any provision of this Agreement is held to be illegal
      or otherwise unenforceable for any reason, such provision shall be severed from this Agreement, but the entire
      Agreement shall not fail on account thereof, and the balance of the Agreement shall remain in full force and
      effect. This Agreement constitutes the entire understanding and agreement between the parties hereto with respect
      to the subject matter hereof. No statement or agreement, oral or written, made prior to this Agreement shall vary
      or modify the written terms hereof. The preprinted terms and conditions contained on any purchase order or other
      document submitted by User to Sherwin-Williams shall not apply to any subscription to, or use of, the Application.
      <br /><br />
      BY CLICKING THE &#8220;I AGREE&#8221; BUTTON, YOU AGREE TO BE BOUND BY AND TO COMPLY WITH THIS AGREEMENT JUST AS
      IF YOU HAD SIGNED IT, AND CLICKING THE &#8220;I AGREE&#8221; BUTTON IS THE LEGAL EQUIVALENT OF YOUR SIGNATURE ON A
      WRITTEN CONTRACT.
    </p>
  </div>
  <div class="flex w-full justify-content-between align-items-end flex-wrap gap-4 pt-3">
    <div class="flex flex-column">
      <div class="flex">
        <p-checkbox [(ngModel)]="isRoofingAgreed" binary="true" inputId="roofing"></p-checkbox>
        <label class="label_check" for="roofing">I agree to the Roofing Passport Terms of Service</label>
      </div>
    </div>
  </div>
  <div class="flex w-full justify-content-between align-items-end flex-wrap gap-4 pt-3">
    <div class="flex flex-column">
      <div class="flex">
        <p-checkbox [(ngModel)]="isSherwinAgreed" binary="true" inputId="roofing"></p-checkbox>
        <label class="label_check" for="roofing"
          >I agree to the
          <a href="https://www.sherwin-williams.com/terms-of-use" class="footerLink link_small" target="_blank">
            &nbsp;Sherwin Williams Terms of Service</a
          >
        </label>
      </div>
    </div>
    <button pButton [disabled]="!canGoNext()" class="main fullMobile" type="button" (click)="onNextClick()">
      Next
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</div>
