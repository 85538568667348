import { ViewportScroller } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faPlus,
  faArrowRight,
  faGear,
  faCheck,
  faUser,
  faCrown,
  faPerson,
  faHandshakeSimple,
  faScrewdriverWrench,
  faBriefcase,
  faBuilding,
  faEarthAmericas,
  faDiagramProject,
  faCircleQuestion,
  faWarning,
  faRemove,
  faL,
} from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "primeng/api";
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from "rxjs";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { ManufacturerInvitation, PayeeOption } from "src/app/core/data/models/ManufacturerInvitation";
import { Manufacturer, UserRole } from "src/app/core/store/app.models";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";
import { GetCompanyNamesResponse } from "../../../core/data/models/GetCompanyNamesResponse";
import { CompanyService } from "../../../shared/services/company.service";
import { GetAffiliatedCompanyNamesResponse } from "../../../core/data/models/GetAffiliatedCompanyNamesResponse";

@Component({
  selector: "app-invite-user",
  templateUrl: "./invite-user.component.html",
  styleUrls: ["./invite-user.component.scss"],
})
export class InviteUserComponent implements OnInit {
  private destroyed$ = new ReplaySubject(1);
  private _companies: BehaviorSubject<GetCompanyNamesResponse[]> = new BehaviorSubject<GetCompanyNamesResponse[]>([]);
  public companies: Observable<GetCompanyNamesResponse[]> = this._companies.asObservable();
  private _affiliatedCompanies: BehaviorSubject<GetAffiliatedCompanyNamesResponse[]> = new BehaviorSubject<
    GetAffiliatedCompanyNamesResponse[]
  >([]);
  public affiliatedCompanies: Observable<GetAffiliatedCompanyNamesResponse[]> = this._companies.asObservable();

  constructor(
    private scroller: ViewportScroller,
    private profileServices: ProfileService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private appState: AppState
  ) {}

  faCircleCheck = faCircleCheck;
  faArrowLeft = faArrowLeft;
  faPlus = faPlus;
  faArrowRight = faArrowRight;
  faGear = faGear;
  faCheck = faCheck;
  faUser = faUser;
  faCrown = faCrown;
  faPerson = faPerson;
  faBuilding = faBuilding;
  faHandshakeSimple = faHandshakeSimple;
  faScrewdriverWrench = faScrewdriverWrench;
  faBriefcase = faBriefcase;
  faEarthAmericas = faEarthAmericas;
  faDiagramProject = faDiagramProject;
  faCircleQuestion = faCircleQuestion;
  faWarning = faWarning;
  faRemove = faRemove;

  activeStep: boolean[] = [true, false, false];
  completedStep: boolean[] = [false, false, false];
  typeSelectedOption: string = "option1";
  billingSelectedOption: string = "option1";
  invitation: ManufacturerInvitation = {
    role: UserRole.Normal,
    email: null,
    firstName: null,
    lastName: null,
    inviterCompanyId: null,
    companyName: null,
    companyId: null,
    contactPhoneNumber: null,
    payee: PayeeOption.Company,
    isCompanyPaying: false,
  };

  ngOnInit(): void {
    this.appState.profile$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.appState.isRPAdmin) {
        this.companyService
          .getCompanyNames()
          .pipe(takeUntil(this.destroyed$))
          .subscribe((companyNames: GetCompanyNamesResponse[]) => {
            this._companies.next(companyNames);
          });
      } else {
        this.companyService
          .getAffiliatedCompanyNames()
          .pipe(takeUntil(this.destroyed$))
          .subscribe((companyNames: GetAffiliatedCompanyNamesResponse[]) => {
            this._affiliatedCompanies.next(companyNames);
          });
        this._companies.next(this.appState.profileManufacturer);
      }
      this.invitation.inviterCompanyId = this.appState.currentProfile.manufacturer.id;
    });
  }

  onCompanyChanged(company: any, type: string) {
    if (type === "inviter") {
      this.invitation.inviterCompanyId = company.id;
    } else if (type === "invitee") {
      this.invitation.companyId = company.id;
      this.invitation.companyName = company.name;
    }
  }

  onIsCompanyPayingChanged() {
    this.invitation.isCompanyPaying = !this.invitation.isCompanyPaying;
  }

  changeStep(currentStep: number, targetStep: number, complete?: boolean) {
    this.activeStep[currentStep] = false;
    this.activeStep[targetStep] = true;
    this.scroller.scrollToAnchor("inviteStep_" + targetStep);

    if (complete) this.completedStep[currentStep] = true;
  }

  selectOption(option: string, step: number) {
    switch (step) {
      case 1:
        this.typeSelectedOption = option;

        if (option === "option1" || option === "option2") {
          this.invitation.inviterCompanyId = this.appState.currentProfile.manufacturer.id;
        } else if (option === "option3") {
          this.invitation.inviterCompanyId = null;
        }

        break;

      case 2:
        this.invitation.role = UserRole[option];
        break;

      default:
        break;
    }
  }

  isDisabled() {
    return !(
      this.isValidEmail(this.invitation.email) &&
      this.invitation.email &&
      this.invitation.firstName &&
      this.invitation.lastName &&
      this.invitation.contactPhoneNumber
    );
  }

  sendInvite() {
    this.profileServices
      .createInvitation(this.invitation)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error?.error?.detail ?? error?.error ?? AppConstants.RoofingPassportCommunicationError;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });
          return EMPTY;
        }),
        tap(() => {
          this.changeStep(2, 2, true);
        })
      )
      .subscribe();
  }

  newInvite() {
    this.activeStep = [true, false, false];
    this.completedStep = [false, false, false];
    this.typeSelectedOption = "option1";
    this.billingSelectedOption = "option1";
    this.invitation = {
      role: UserRole.Normal,
      email: null,
      firstName: null,
      lastName: null,
      inviterCompanyId: null,
      companyName: null,
      companyId: null,
      contactPhoneNumber: null,
      payee: PayeeOption.Company,
      isCompanyPaying: false,
    };
  }

  private isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
