import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ParamMap, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {Checkbox} from "primeng/checkbox";
import {BehaviorSubject, Observable, ReplaySubject, Subscription, zip} from "rxjs";
import {map} from "rxjs/operators";
import {Manufacturer} from "src/app/core/data/models/AppInitializationData";
import {AdminService} from "src/app/shared/services/admin.service";
import {AppState} from "src/app/shared/services/app-state";
import {
  faArrowUpRightFromSquare,
  faPen,
  faSave,
  faExclamationCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import states from "src/app/core/data/states";
import {ProfileService} from "../../../../shared/services/profile.service";
import {CompanyService} from "../../../../shared/services/company.service";
import {GetCompanyResponse} from "../../../../core/data/models/GetCompanyResponse";
import {ActivatedRoute} from "@angular/router";
import {UpdateCompanyRequest} from "../../../../core/data/models/UpdateCompanyRequest";
import {CreateCompanyRequest} from "src/app/core/data/models/CreateCompanyRequest";
import {Address} from "src/app/core/data/models/CreateOrderRequest";
import {PointOfContact} from "src/app/core/data/models/PointOfContact";

@Component({
  selector: "app-edit-company-profile",
  templateUrl: "./edit-company-profile.component.html",
  styleUrls: ["./edit-company-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCompanyProfileComponent implements OnInit, OnDestroy {
  @Output() onCancel = new EventEmitter();

  private destroyed$ = new ReplaySubject(1);
  busy: Subscription;
  errorMessage: string;
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  profile$ = this.appState.profile$;
  checked = true;
  enableWRXJobs = "enableWRXJobs";
  enableWRX = "enableWRX";
  @ViewChild("wrxEnabled") wrxEnabled: Checkbox;
  @ViewChild("wrxJobsEnabled") wrxJobsEnabled: Checkbox;
  private _manufacturers: BehaviorSubject<Manufacturer[]> = new BehaviorSubject<Manufacturer[]>(
    this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
  );
  public manufacturers: Observable<Manufacturer[]> = this._manufacturers.asObservable();
  companyForm: FormGroup;
  states = states;
  company: GetCompanyResponse;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faPen = faPen;
  faSave = faSave;
  faTimes = faTimes;
  faExclamationCircle = faExclamationCircle;
  isBackLabel: boolean = true;

  originalValues: {
    manufacturerId: number;
    name: string;
    description?: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    website?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhone?: string;
    roofingWRXEnabled: boolean;
    roofingWRXJobsEnabled: boolean;
    roofingWRXCompanyId: number;
    isSmartBuildVisible: boolean;
  };

  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private adminService: AdminService,
    private messageService: MessageService,
    private router: Router,
    private profileService: ProfileService,
    private companyService: CompanyService,
    private route: ActivatedRoute
  ) {
    this.setupForm();
  }

  get manufacturerId() {
    return this.companyForm.get("manufacturerId");
  }

  get name() {
    return this.companyForm.get("name");
  }

  get description() {
    return this.companyForm.get("description");
  }

  get street() {
    return this.companyForm.get("street");
  }

  get city() {
    return this.companyForm.get("city");
  }

  get state() {
    return this.companyForm.get("state");
  }

  get postalCode() {
    return this.companyForm.get("postalCode");
  }

  get website() {
    return this.companyForm.get("website");
  }

  get contactFirstName() {
    return this.companyForm.get("contactFirstName");
  }

  get contactLastName() {
    return this.companyForm.get("contactLastName");
  }

  get contactEmail() {
    return this.companyForm.get("contactEmail");
  }

  get contactPhone() {
    return this.companyForm.get("contactPhone");
  }

  get roofingWRXCompanyId() {
    return this.companyForm.get("roofingWRXCompanyId");
  }

  get roofingWRXEnabled() {
    return this.companyForm.get("roofingWRXEnabled");
  }

  get roofingWRXJobsEnabled() {
    return this.companyForm.get("roofingWRXJobsEnabled");
  }

  get isSmartBuildVisible() {
    return this.companyForm.get("isSmartBuildVisible");
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const companyId = parseInt(params.get("id"));
      if (companyId) {
        this.fetchCompanyData(companyId);
      }
    });

    this.appState.profile$.subscribe((profile) => {
      this._manufacturers.next(
        this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
      );
    });

    this.companyForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
  }

  checkFormChanges(): void {
    this.isBackLabel = this.companyForm.dirty;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setupForm() {
    this.companyForm = this.fb.group({
      manufacturerId: [0, Validators.required],
      name: ["", [Validators.required, Validators.maxLength(50)]],
      description: [""],
      street: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postalCode: ["", Validators.required],
      website: ["", Validators.maxLength(50)],
      contactFirstName: ["", Validators.maxLength(50)],
      contactLastName: ["", Validators.maxLength(50)],
      contactEmail: ["", [Validators.email, Validators.maxLength(50)]],
      contactPhone: ["", Validators.maxLength(50)],
      roofingWRXEnabled: [true],
      roofingWRXJobsEnabled: [true],
      roofingWRXCompanyId: [0, Validators.pattern(/\d+/)],
      isSmartBuildVisible: [false],
    });

    this.originalValues = {...this.companyForm.value};
  }

  patchForm(company: GetCompanyResponse) {
    const names = this.parseContactName(company.contactName);
    const firstName = names.length > 0 ? names[0] : "";
    const lastName = names.length > 1 ? names.slice(1).join(" ") : "";
    this.companyForm.patchValue({
      name: company.name,
      manufacturerId: company.id,
      description: company.description,
      street: company.street,
      city: company.city,
      postalCode: company.postalCode,
      state: company.state,
      website: company.website,
      contactFirstName: firstName,
      contactLastName: lastName,
      contactEmail: company.contactEmail,
      contactPhone: company.contactPhone,
      roofingWRXEnabled: true,
      roofingWRXJobsEnabled: true,
      roofingWRXCompanyId: company.roofingWRXCompanyId,
      isSmartBuildVisible: company.isSmartBuildVisible,
    });

    this.companyForm.markAsPristine();
    this.companyForm.markAsUntouched();

    if (company) {
      this.companyForm.markAllAsTouched();
    }
  }

  fetchCompanyData(companyId: number) {
    this.companyService.getCompany(companyId).subscribe((company) => {
      this.company = company;
      this.patchForm(company);
      this.originalValues = {...this.companyForm.value};
    });
  }

  setState(value: string): void {
    this.companyForm.get("state")?.setValue(value);
  }

  onChecked(checkboxType: Checkbox): void {
    if (checkboxType.value === this.enableWRX) {
      checkboxType.checked() ? this.roofingWRXEnabled.setValue(true) : this.roofingWRXEnabled.setValue(false);
    }
    if (checkboxType.value === this.enableWRXJobs) {
      checkboxType.checked() ? this.roofingWRXJobsEnabled.setValue(true) : this.roofingWRXJobsEnabled.setValue(false);
    }
  }

  save() {
    if (this.company && this.company.id > 0) {
      const contactName = this.toContactName(this.contactFirstName.value, this.contactLastName.value);
      let updateCompanyRequest: UpdateCompanyRequest = {
        id: this.company.id,
        name: this.name.value,
        description: this.description.value,
        street: this.street.value,
        city: this.city.value,
        postalCode: this.postalCode.value,
        state: this.state.value,
        website: this.website.value,
        contactName: contactName,
        contactEmail: this.contactEmail.value,
        contactPhone: this.contactPhone.value,
        roofingWRXEnabled: this.roofingWRXEnabled.value,
        roofingWRXJobsEnabled: this.roofingWRXJobsEnabled.value,
        roofingWRXCompanyId: this.roofingWRXCompanyId.value,
        isSmartBuildVisible: this.isSmartBuildVisible.value,
      };
      if (this.roofingWRXCompanyId.dirty && !this.roofingWRXCompanyId.value) {
        updateCompanyRequest.roofingWRXCompanyId = null;
      }

      if (this.companyForm.valid) {
        this.adminService
          .updateCompany(updateCompanyRequest)
          .pipe(
            map((x: any) => {
              if (!x.error?.message && x.manufacturer) {
                this._manufacturers.next(
                  this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
                );
                this.manufacturerId.setValue(x.manufacturer.id);

                this.profileService.validateProfile().subscribe(() =>
                  this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Company profile successfully updated.",
                  })
                );

                this.originalValues = this.companyForm.value;
                this.companyForm.markAsPristine();
              } else {
                this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: x.error.error.detail ?? "An unexpected error occurred.",
                });
              }
            })
          )
          .subscribe();
      }
    } else {
      let address: Address = {
        street: this.street.value,
        city: this.city.value,
        state: this.state.value,
        zip: this.postalCode.value,
        countryCode: "USA",
      };
      let pointOfContact: PointOfContact = {
        name: this.name.value,
        email: this.contactEmail.value,
        phoneNumber: this.contactPhone.value,
      };

      const contactName = this.toContactName(this.contactFirstName.value, this.contactLastName.value);

      let createCompanyRequest: CreateCompanyRequest = {
        externalRequestId: "1234",
        externalCompanyId: "1234",
        name: this.name.value,
        description: this.description.value,
        street: this.street.value,
        city: this.city.value,
        postalCode: this.postalCode.value,
        state: this.state.value,
        address: address,
        websiteUrl: this.website.value,
        businessType: "Company",
        pointOfContact: pointOfContact,
        contactName: contactName,
        contactEmail: this.contactEmail.value,
        phoneNumber: this.contactPhone.value,
        roofingWRXEnabled: this.roofingWRXEnabled.value,
        roofingWRXJobsEnabled: this.roofingWRXJobsEnabled.value,
        roofingWRXCompanyId: this.roofingWRXCompanyId.value,
      };

      if (this.roofingWRXCompanyId.dirty && !this.roofingWRXCompanyId.value) {
        createCompanyRequest.roofingWRXCompanyId = null;
      }

      if (this.companyForm.valid) {
        this.adminService
          .createCompany(createCompanyRequest)
          .pipe(
            map((x: any) => {
              if (!x.error?.message && x.manufacturer) {
                this._manufacturers.next(
                  this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
                );
                this.manufacturerId.setValue(x.manufacturer.id);

                this.profileService.validateProfile().subscribe(() => {
                  this.messageService.add({
                    severity: "success",
                    summary: "Success",
                    detail: "New company request successfully created",
                  });

                  return this.redirectToCompanies();
                });

                this.originalValues = this.companyForm.value;
                this.companyForm.markAsPristine();
                this.clearForm();
              } else {
                this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: x.error.error.detail ?? "",
                });
              }
            })
          )
          .subscribe();
      }
    }
  }

  clearForm(): void {
    this.companyForm.reset({
      manufacturerId: "",
      name: "",
      description: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      website: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      roofingWRXCompanyId: "",
    });
  }

  isDisabled(): boolean {
    const currentValues = this.companyForm.value;
    const valuesChanged =
      currentValues.manufacturerId !== this.originalValues.manufacturerId ||
      currentValues.name !== this.originalValues.name ||
      currentValues.description !== this.originalValues.description ||
      currentValues.street !== this.originalValues.street ||
      currentValues.city !== this.originalValues.city ||
      currentValues.state !== this.originalValues.state ||
      currentValues.postalCode !== this.originalValues.postalCode ||
      currentValues.website !== this.originalValues.website ||
      currentValues.contactName !== this.originalValues.contactName ||
      currentValues.contactEmail !== this.originalValues.contactEmail ||
      currentValues.contactPhone !== this.originalValues.contactPhone ||
      currentValues.roofingWRXEnabled !== this.originalValues.roofingWRXEnabled ||
      currentValues.roofingWRXJobsEnabled !== this.originalValues.roofingWRXJobsEnabled ||
      currentValues.roofingWRXCompanyId !== this.originalValues.roofingWRXCompanyId ||
      currentValues.smartBuildVisible !== this.originalValues.isSmartBuildVisible;

    return !(this.companyForm.valid && this.companyForm.dirty && valuesChanged);
  }

  returnToProjectList() {
    return this.router.navigate(["../jobs"]);
  }

  redirectToCompanies() {
    return this.router.navigate(["admin/companies"]);
  }

  private toContactName(firstName: string, lastName: string): string {
    const trimmedFirstName = firstName ? firstName.trim() : "";
    const trimmedLastName = lastName ? lastName.trim() : "";

    return `${trimmedFirstName} ${trimmedLastName}`.trim();
  }

  private parseContactName(contactName: string): string[] {
    const result: string[] = [];
    if (!contactName || contactName.trim() === "") {
      return result;
    }

    if (!contactName.includes(" ")) {
      result.push(contactName);
      return result;
    }

    const names = contactName.split(" ");

    if (names.length > 0) {
      result.push(names[0]);
    }

    if (names.length === 2) {
      result.push(names[1]);
    }

    if (names.length > 2) {
      result.push(names.slice(1).join(" "));
    }

    return result;
  }
}
