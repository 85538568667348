import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import { AppConstants } from "src/app/shared/constants/app-constants";

type DataSource = keyof typeof AppConstants.ThirdPartyServiceName;

@Component({
  selector: "app-creation-failed",
  templateUrl: "./creation-failed.component.html",
  styleUrls: ["./creation-failed.component.scss"],
})
export class CreationFailedComponent {
  faXmark = faXmark;
  faPlus = faPlus;
  isVisible: boolean = true;

  constructor(private router: Router) {}

  hideAndNavigate(): void {
    this.isVisible = false;
    this.router.navigate(["/jobs/new"]);
  }
}
