import {Component, OnInit} from "@angular/core";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {ProfileService} from "src/app/shared/services/profile.service";
import {GetSelectCompanyResponse} from "../../core/data/models/GetSelectCompanyResponse";
import {HeaderService} from "../../shared/services/header.service";

@Component({
  selector: "app-select-company",
  templateUrl: "./select-company.component.html",
  styleUrls: ["./select-company.component.scss"],
})
export class SelectCompanyComponent implements OnInit {
  profiles: GetSelectCompanyResponse[] = [];

  faArrowRight = faArrowRight;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private headerService: HeaderService,
  ) {
    this.headerService.setIsSelectingCompany(true);
  }

  ngOnInit(): void {
    this.profileService.getSelectCompany().subscribe((response: GetSelectCompanyResponse[]) => {
      this.profiles = response;
    });

    this.headerService.setIsSelectingCompany(true);
  }

  selectProfile(profile: GetSelectCompanyResponse) {
    this.profileService.chooseProfile(profile.userProfileId).subscribe(() => {
      this.headerService.setIsSelectingCompany(false);
      return this.router.navigate(["jobs"]);
    });
  }
}
