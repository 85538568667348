import { Component, OnInit } from "@angular/core";
import { ICustomWindow, WindowRefService } from "src/app/shared/helpers/window-ref.service";

@Component({
  selector: "app-demo-header",
  templateUrl: "./demo-header.component.html",
  styleUrls: ["./demo-header.component.scss"],
})
export class DemoHeaderComponent implements OnInit {
  private window: ICustomWindow;

  constructor(private windowRefService: WindowRefService) {
    this.window = this.windowRefService.nativeWindow;
  }

  ngOnInit(): void {}

  exitDemo() {
    this.window.location.href = "/jobs";
  }
}
