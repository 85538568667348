<p-dialog
  [styleClass]="jobDataSource === 'option1' ? 'w-40rem overflow-hidden' : 'w-30rem overflow-hidden'"
  [(visible)]="isVisible"
  [modal]="true"
  [draggable]="false"
  (onHide)="onContinueClick()"
>
  <app-eagleview-third-step
    *ngIf="jobDataSource === 'option1'"
    [onClose]="closeModal"
    [onContinueClick]="onContinueClick"
  ></app-eagleview-third-step>
  <app-xml-third-step
    *ngIf="jobDataSource === 'option2'"
    [onClose]="closeModal"
    [onContinueClick]="onContinueClick"
  ></app-xml-third-step>
  <app-blueprints-third-step
    *ngIf="jobDataSource === 'option3'"
    [onClose]="closeModal"
    [onContinueClick]="onContinueClick"
  ></app-blueprints-third-step>
</p-dialog>
