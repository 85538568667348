import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import {
  faBuilding,
  faCheck,
  faCircleInfo,
  faHandshake,
  faPlus,
  faXmark,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "primeng/api";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AdminTask } from "src/app/core/data/models/AdminTask";
import { PartnerRegistration } from "src/app/core/data/models/PartnerRegistration";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { ProfileService } from "src/app/shared/services/profile.service";
import { AddressData } from "../../../../core/data/models/ManufacturerInvitation";

@Component({
  selector: "work-queue-details-modal",
  templateUrl: "./work-queue-details-modal.component.html",
  styleUrls: ["./work-queue-details-modal.component.scss"],
})
export class WorkQueueDetailsModal implements OnInit, OnChanges {
  @Input() selectedTask: AdminTask = {} as AdminTask;
  @Input() isVisible: boolean;
  @Input() isCompanyRequest: boolean;
  @Output() onClose = new EventEmitter<void>();

  private _isCompany: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCompany: Observable<boolean> = this._isCompany.asObservable();

  constructor(
    private profileService: ProfileService,
    private messageService: MessageService
  ) {
  }

  ngOnChanges(changes) {
    if (changes.isCompanyRequest) {
      this._isCompany.next(changes.isCompanyRequest.currentValue);
    }
    this.selectedTask;
  }

  ngOnInit(): void {
  }

  faPlus = faPlus;
  faCheck = faCheck;
  faXmark = faXmark;
  faBuilding = faBuilding;
  faHandshake = faHandshake;
  faCircleInfo = faCircleInfo;
  faEyeSlash = faEyeSlash;
  faEye = faEye;

  isAccepted: boolean = null;
  username: string = null;
  password: string = null;
  isError: boolean = false;
  placeholderOptions: any[] = [{ name: "Lorem ipsum" }, { name: "Dolor sit amet" }];
  companyRoleSelectedOption: string = "option1";
  billingSelectedOption: string = "option1";
  showPassword: boolean = false;

  acceptOption(option: boolean) {
    this.isAccepted = option;
  }

  selectRoleOption(option: string) {
    this.companyRoleSelectedOption = option;
  }

  submit() {
    if (!this.isAccepted) {
      this.profileService
        .rejectRegistration(this.selectedTask.id)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error?.error?.detail ?? AppConstants.RoofingPassportCommunicationError;
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: errorMessage,
            });
            return EMPTY;
          }),
          tap(() => {
            this.close();
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Task rejected.",
            });
          })
        )
        .subscribe();

      return;
    }
    if ((this.username != null && this.password != null) || this._isCompany.value) {
      let data: PartnerRegistration = {
        userId: this.selectedTask.inviteeUserId?.toString(),
        context: this.selectedTask.partnerSystem,
        manufacturerId: this.selectedTask.inviteeCompanyId?.toString(),
        username: this.username,
        password: this.password,
        task: this.selectedTask,
      };

      this.profileService
        .completeRegistration(data, this.selectedTask.id)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error?.error?.detail ?? AppConstants.RoofingPassportCommunicationError;
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: errorMessage,
            });
            return EMPTY;
          }),
          tap(() => {
            this.close();
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Task completed.",
            });
          })
        )
        .subscribe();
    }
  }

  close() {
    this.username = null;
    this.password = null;
    this.selectedTask = {} as AdminTask;
    this.isAccepted = null;
    this.onClose.emit();
  }

  canSubmit(): boolean {
    if (this.isAccepted === false) {
      return true;
    }

    if (this.isAccepted === true) {
      return !!(this._isCompany.value || (this.username && this.password));
    }

    return false;
  }

  getFullInviteeAddress(adminTask: AdminTask): string {
    if (!adminTask || !adminTask.inviteeCompanyAddress) {
      return ""; // Return an empty string if the address is missing
    }

    const address: AddressData = adminTask.inviteeCompanyAddress;

    const { street, city, state, postalCode } = address;

    const fullAddress = [street, city, state, postalCode].filter(Boolean).join(", ");

    return fullAddress || "";
  }
}
