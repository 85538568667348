export const NEW_JOB_FEATURE_FLAGS: Record<string, boolean> = {
  PROCESS_MANUALLY: false,
  CHECK_EAGLEVIEW_PAYMENT_SETUP: false,
};

export const CREATE_COMPANY_FEATURE_FLAGS: Record<string, boolean> = {
  THREE_DOTS_PANEL: false,
  CREATE_NEW_COMPANY: false,
};

export const REGISTER: Record<string, boolean> = {
  REJECT_INVITATION: false,
};

export const DEMO_FEATURE_FLAGS: Record<string, boolean> = {
  REQUEST_EMAIL: false,
  SHOW_RP_ENGINE: false,
  DOWNLOAD_TAKEOFF_REPORT: false,
};
