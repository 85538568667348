<p-header>
  <h2 class="font-light text-2xl">How data providers work</h2>
</p-header>
<p class="text-sm mt-3">Visualize how we create your takeoff report:</p>

<div class="flex flex-column align-items-center mt-4">
  <div class="border-1 border-main py-3 px-4 flex align-items-center justify-content-center font-semibold">
    <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="w-2rem" />
    <span class="text-sm ml-4">SmartBuild</span>
  </div>
</div>

<p-divider></p-divider>
<p class="text-sm">
  Using <span class="font-semibold">XML</span> has the advantages of being compatible with different sources.
</p>
<div class="flex justify-content-between mt-4">
  <div class="ml-auto">
    <button pButton (click)="onClose()">Continue</button>
  </div>
</div>
