import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import states from "src/app/core/data/states";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export interface Address {
  street: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zip: FormControl<string>;
}

@Component({
  selector: "app-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"],
})
export class AddressFormComponent implements OnInit {
  @Input() addressGroup!: FormGroup<Address>;

  faTriangleExclamation = faTriangleExclamation;

  stateList = states;

  constructor() {}

  ngOnInit(): void {}
}
