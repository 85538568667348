<p-accordion
  *ngIf="!completedStep[0] || !completedStep[1] || !completedStep[2] || !completedStep[3]"
  [multiple]="false"
>
  <!-- Step 0 -->
  <step-card
    id="jobStep_0"
    number="1"
    label="Expected outcome"
    [selected]="activeStep[0]"
    textSelected="What do you need to obtain?"
    [completed]="completedStep[0]"
    [textCompleted]="
      isRoofingPassportEngine()
        ? 'Get a takeoff report, processed by Roofing Passport'
        : 'Get a takeoff report and edit in SmartBuild'
    "
  >
    <h2 class="srOnly">Expected outcome</h2>

    <section class="mb-4">
      <option-box
        *ngIf="DEMO_FEATURE_FLAGS.SHOW_RP_ENGINE"
        label="Get a takeoff report"
        subtitle="Generate a takeoff ready for estimation and ordering."
        [id]="'jobExpectedOutcomeOption_1'"
        name="jobExpectedOutcome"
        [value]="EXPECTED_OUTCOME.takeOffReport"
        [selectedValue]="expectedOutcome"
        (optionSelected)="selectExpectedOutcome(EXPECTED_OUTCOME.takeOffReport)"
        [checked]="expectedOutcome === EXPECTED_OUTCOME.takeOffReport"
        styleClass="expectedOutcomeOptionBox"
      ></option-box>
      <option-box
        label="Get a takeoff report and edit in SmartBuild"
        subtitle="After generating your takeoff report, you can use SmartBuild to accurately estimate material and labor costs."
        [id]="'jobExpectedOutcomeOption_2'"
        name="jobExpectedOutcome"
        [value]="EXPECTED_OUTCOME.takeOffReportAndEditInSmartBuild"
        [selectedValue]="expectedOutcome"
        (optionSelected)="selectExpectedOutcome(EXPECTED_OUTCOME.takeOffReportAndEditInSmartBuild)"
        [checked]="expectedOutcome === EXPECTED_OUTCOME.takeOffReportAndEditInSmartBuild"
        styleClass="mt-4 expectedOutcomeOptionBox"
      ></option-box>
      <div class="border-bottom-1 border-right-1 border-left-1 border-outline flex justify-content-between p-3">
        <div class="flex align-items-center gap-2">
          <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="w-3rem" />
          <p class="text-xs">You don't have a linked SmartBuild account.</p>
        </div>
        <div class="flex column-gap-3">
          <button disabled pButton type="button" class="fullMobile">Add account</button>
          <app-info-button
            pTooltip="About SmartBuild account"
            (click)="toggleDemoAlert('smartBuildAccount', $event)"
          ></app-info-button>
        </div>
      </div>
      <alert
        *ngIf="demoAlerts.smartBuildAccount"
        color="main"
        [icon]="faCircleInfo"
        label="SmartBuild account"
        description="A user will be prompted to add a Smartbuild account if they don't have one."
        styleClass="mt-3"
      ></alert>
    </section>

    <div class="divider mt-4 mb-4"></div>
    <section class="footActionsCont spaced">
      <button pButton type="button" class="fullMobile" (click)="advanceToStep1()">
        Next
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
    </section>
  </step-card>

  <!-- Step 1 -->
  <step-card
    id="jobStep_1"
    number="2"
    label="Data source"
    [selected]="activeStep[1]"
    [textSelected]="'Pick the method you want to use to obtain dimensions data:'"
    [completed]="completedStep[1]"
    [textCompleted]="getTextCompletedStep1()"
    [iconCompleted]="iconCompleted"
  >
    <h2 class="srOnly">Data source</h2>

    <section *ngIf="isRoofingPassportEngine()" class="mb-4 w-full">
      <div class="w-full lg:w-24rem lg:mr-3 mb-2 lg:mb-0">
        <label for="jobInput_roofingSystem" class="label">Roofing System</label>
        <p-dropdown
          [options]="roofingSystems"
          placeholder="Select Roofing System"
          [(ngModel)]="roofingSystem"
          id="jobInput_roofingSystem"
          required
          class="w-full"
        ></p-dropdown>
      </div>
      <form [formGroup]="jobDimensions" class="w-full grid mt-3">
        <div class="col-12 lg:col-3">
          <label for="jobInput_panelCoverageWidth" class="label">Panel Coverage Width (inches)</label>
          <input
            pInputText
            id="jobInput_panelCoverageWidth"
            type="number"
            class="w-full"
            placeholder="0"
            formControlName="panelCoverageWidth"
            readonly
            disabled
          />
        </div>

        <!-- Clip System Fields -->
        <ng-container *ngIf="roofingSystem === panelTypesEnum.CFC">
          <div class="col-12 lg:col-3">
            <label for="jobInput_clipSpacingPerIoc" class="label">Clip Spacing per IOC (inches)</label>
            <input
              pInputText
              id="jobInput_clipSpacingPerIoc"
              type="number"
              class="w-full"
              placeholder="24"
              formControlName="clipSpacingPerIoc"
              readonly
              disabled
            />
          </div>
          <div class="col-12 lg:col-3">
            <label for="jobInput_fastenersPerClip" class="label">Fasteners per Clip</label>
            <input
              pInputText
              id="jobInput_fastenersPerClip"
              type="number"
              class="w-full"
              placeholder="1"
              formControlName="fastenersPerClip"
              readonly
              disabled
            />
          </div>
        </ng-container>

        <!-- Nail Strip Fields -->
        <ng-container *ngIf="roofingSystem === panelTypesEnum.CFS">
          <div class="col-12 lg:col-3">
            <label for="jobInput_panelFastenersPerIoc" class="label">Panel Fasteners per IOC (inches)</label>
            <input
              pInputText
              id="jobInput_panelFastenersPerIoc"
              type="number"
              class="w-full"
              placeholder="12"
              formControlName="panelFastenersPerIoc"
              readonly
              disabled
            />
          </div>
        </ng-container>

        <!-- Exposed Fastener Fields -->
        <ng-container *ngIf="roofingSystem === panelTypesEnum.XF">
          <div class="col-12 lg:col-3">
            <label for="jobInput_panelFastenersPerSquare" class="label">Panel Fasteners per Square</label>
            <input
              pInputText
              id="jobInput_panelFastenersPerSquare"
              type="number"
              class="w-full"
              placeholder="85"
              formControlName="panelFastenersPerSquare"
              readonly
              disabled
            />
          </div>
        </ng-container>
      </form>
    </section>

    <section class="optionsCont mb-4">
      <h3 class="srOnly">Select source</h3>
      <option-box
        [id]="'jobDataSourceOption_1'"
        name="jobDataSource"
        value="option1"
        [selectedValue]="step1SelectedOption"
        (optionSelected)="selectJobDataSource(1)"
        [checked]="step1SelectedOption === 'option1'"
        [icon]="faLocationDot"
        label="Address"
      >
        <span>Obtain dimensions data from a location based on accurate aerial views provided by EagleView.</span>
      </option-box>
      <option-box
        [id]="'jobDataSourceOption_3'"
        name="jobDataSource"
        value="option3"
        [selectedValue]="step1SelectedOption"
        (optionSelected)="selectJobDataSource(3)"
        [icon]="faMap"
        label="Blueprints"
        subtitle="& other documents"
      >
        <span
          >Upload any plans and documents related to your project. This data source is only supported by Roofing
          WRX.</span
        >
      </option-box>
      <option-box
        [id]="'jobDataSourceOption_2'"
        name="jobDataSource"
        value="option2"
        [selectedValue]="step1SelectedOption"
        (optionSelected)="selectJobDataSource(2)"
        [icon]="faFile"
        label="XML File"
      >
        <span>Bring your own XML roofing geometry file from a third party.</span>
      </option-box>
    </section>

    <section class="grid" *ngIf="step1SelectedOption === 'option1' && isEagleViewActive">
      <h3 class="srOnly">Address settings</h3>
      <div class="col-12 md:col-6" [formGroup]="newJobLocation">
        <div class="inputCont">
          <label for="jobInput_jobName1" class="label">Job name</label>
          <input
            pInputText
            id="jobInput_jobName1"
            type="text"
            name="name"
            class="w-full"
            placeholder="Job name"
            formControlName="name"
            readonly
          />
        </div>
        <div class="inputCont">
          <label for="jobInput_jobType1" class="label">Job Type</label>
          <div class="flex column-gap-3">
            <p-dropdown
              [options]="jobTypes"
              optionLabel="name"
              class="w-full"
              placeholder="Select job type"
              formControlName="jobType"
              optionValue="name"
              id="jobInput_jobType1"
              [disabled]="true"
            ></p-dropdown>
          </div>
        </div>
        <div class="inputCont">
          <label for="jobInput_address" class="label">Address</label>
          <input
            pInputText
            id="jobInput_address"
            type="text"
            name="middletName"
            class="w-full"
            placeholder="Address"
            formControlName="address"
            readonly
          />
        </div>
        <div class="inputCont">
          <label for="jobInput_state" class="label">State/province</label>
          <p-dropdown
            [options]="stateList"
            optionLabel="name"
            class="w-full"
            placeholder="Select state"
            formControlName="state"
            optionValue="name"
            id="jobInput_state"
            optionValue="abbreviation"
            [disabled]="true"
          ></p-dropdown>
        </div>
        <div class="inputCont">
          <label for="jobInput_city" class="label">City</label>
          <input
            pInputText
            id="jobInput_city"
            class=""
            type="text"
            name="middletName"
            class="w-full"
            placeholder="City"
            formControlName="city"
            readonly
          />
        </div>
        <div class="inputCont">
          <label for="jobInput_ZIPCode" class="label">ZIP code</label>
          <div class="flex column-gap-3">
            <input
              pInputText
              id="jobInput_ZIPCode"
              type="text"
              name="middletName"
              formControlName="zip"
              class="w-full max-w-10rem"
              placeholder="ZIP code"
              readonly
            />
            <app-info-button pTooltip="About Address" (click)="toggleDemoAlert('address', $event)"></app-info-button>
          </div>
          <alert
            *ngIf="demoAlerts.address"
            color="main"
            [icon]="faCircleInfo"
            label="Address"
            description="By entering any address covered by Eagleview (as of now, the entirety of the US and Canada), the system will automatically identify any roof in that location and get measurement data."
            styleClass="mt-3"
          ></alert>
        </div>
        <div class="inputCont flex">
          <p-checkbox
            inputId="jobInput_changedRecentCheck"
            name="groupname"
            [binary]="true"
            formControlName="changesInLast4Years"
          ></p-checkbox>
          <label for="jobInput_changedRecentCheck" class="label_check mr-3"> Changed in the last 4 years </label>
          <app-info-button
            pTooltip="About Updated data"
            (click)="toggleDemoAlert('updatedData', $event)"
          ></app-info-button>
        </div>
        <alert
          *ngIf="demoAlerts.updatedData"
          color="main"
          [icon]="faCircleInfo"
          label="Updated data"
          description="In cases Eagleview data might be outdated, you can request Eagleview to perform a manual update of your location."
          styleClass="mb-3"
        ></alert>
        <button disabled pButton type="button" class="outline" (click)="findAddress()">
          <fa-icon [icon]="faSearch"></fa-icon>
          Locate address
        </button>
      </div>
      <div class="col-12 md:col-6">
        <img src="assets/img/placeholder/demo-map.png" alt="Demo Map" class="w-full max-h-41rem" />
      </div>
      <div class="col-12 md:col-6 ml-auto flex flex-column align-items-end">
        <app-info-button pTooltip="About EagleView" (click)="toggleDemoAlert('eagleViewMap', $event)"></app-info-button>
        <alert
          *ngIf="demoAlerts.eagleViewMap"
          color="main"
          [icon]="faCircleInfo"
          label="EagleView"
          description="The map provides you with an accurate aerial view of your job location."
          styleClass="mt-3"
        ></alert>
      </div>
    </section>

    <section *ngIf="step1SelectedOption === 'option2'">
      <h3 class="srOnly">XML settings</h3>
      <div class="grid">
        <div class="col-12 md:col-6" [formGroup]="newJobXML">
          <div class="inputCont">
            <label for="jobInput_jobName2" class="label">Job name</label>
            <input
              pInputText
              id="jobInput_jobName2"
              type="text"
              name="jobName"
              class="w-full"
              formControlName="name"
              placeholder="Job name"
              (blur)="nameValidation('XML')"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobName2"
              *ngIf="xmlFormJobName.invalid && (xmlFormJobName.touched || xmlFormJobName.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a job name
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_jobType2" class="label">Job Type</label>
            <div class="flex column-gap-3">
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType2"
                optionValue="name"
              ></p-dropdown>
            </div>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobType2"
              *ngIf="xmlFormJobType.invalid && (xmlFormJobType.touched || xmlFormJobType.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a job type
            </label>
          </div>
        </div>
        <div class="col-12 md:col-6 pointer-events-none">
          <file-drop-input
            [id]="'jobInput_fileXML'"
            name="jobInput_fileXML"
            accept=".xml"
            acceptText="XML"
            label="XML file"
            (change)="fileUpload($event)"
            (drop)="drop($event)"
            [fileList]="uploadFiles"
          ></file-drop-input>
          <label
            htmlFor="jobInput_fileXML"
            id="username2-help"
            class="p-invalid"
            *ngIf="step1SelectedOption === 'option2' && newJobXML.invalid && uploadFiles.length === 0"
            >Please upload your XML file.
          </label>
          <small class="inputDescription">
            The XML file must be formatted with 3D point, edge, and face information. XML takeoffs from EagleView,
            TopView (v9.19 or later) and Hover are also compatible.
          </small>
        </div>
        <div class="col-12 md:col-6 ml-auto mt-3 flex flex-column align-items-end">
          <app-info-button pTooltip="About XML files" (click)="toggleDemoAlert('xmlFile', $event)"></app-info-button>
          <alert
            *ngIf="demoAlerts.xmlFile"
            color="main"
            [icon]="faCircleInfo"
            label="Uploading XML files"
            description="The map provides you with an accurate aerial view of your job location."
            styleClass="mt-3"
          ></alert>
        </div>
      </div>
    </section>

    <section *ngIf="step1SelectedOption === 'option3'">
      <h3 class="srOnly">Blueprints settings</h3>
      <div class="grid">
        <div class="col-12 md:col-6" [formGroup]="newJobBluePrint">
          <div class="inputCont">
            <label for="jobInput_jobName3" class="label">Job name</label>
            <input
              pInputText
              id="jobInput_jobName3"
              type="text"
              name="jobName"
              class="w-full"
              placeholder="Job name"
              formControlName="name"
              (blur)="nameValidation('Blueprints')"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobName3"
              *ngIf="bluePrintsFormJobName.invalid && (bluePrintsFormJobName.touched || bluePrintsFormJobName.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a job name
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_jobType1" class="label">Job Type</label>
            <div class="flex column-gap-3">
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType1"
                optionValue="name"
              ></p-dropdown>
            </div>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobType1"
              *ngIf="bluePrintsFormJobType.invalid && (bluePrintsFormJobType.touched || bluePrintsFormJobType.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a job type
            </label>
          </div>
          <div class="inputCont">
            <p class="label">Pricing model</p>
            <div class="inputCont_check">
              <p-radioButton
                inputId="jobInput_structure_single"
                name="pricingmodel"
                formControlName="pricingmodel"
                value="Single"
              ></p-radioButton>
              <label for="jobInput_structure_single" class="label_check">Single structure</label>
            </div>
            <div class="inputCont_check flex">
              <p-radioButton
                inputId="jobInput_structure_multiple"
                name="pricingmodel"
                formControlName="pricingmodel"
                value="Multi"
              ></p-radioButton>
              <label for="jobInput_structure_multiple" class="label_check mr-3">Multiple structures</label>
            </div>
            <p
              id="username2-help"
              class="p-invalid"
              *ngIf="bluePrintsPricingmodel.invalid && (bluePrintsPricingmodel.touched || bluePrintsPricingmodel.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a pricing model
            </p>
          </div>
          <div class="inputCont">
            <label for="jobInput_coments" class="label">Comments</label>
            <textarea
              pInputTextarea
              id="jobInput_coments"
              class="w-full"
              placeholder="Comments"
              formControlName="comments"
            ></textarea>
          </div>
        </div>
        <div class="col-12 md:col-6 pointer-events-none">
          <file-drop-input
            [id]="'jobInput_fileXML'"
            name="jobInput_fileXML"
            [multiple]="true"
            accept=".pdf,image/png,image/jpeg"
            acceptText="PDF, JPG, PNG"
            label="Project files"
            (change)="fileUpload($event)"
            (drop)="drop($event)"
            [fileList]="uploadFiles"
          ></file-drop-input>
          <label
            htmlFor="jobInput_fileXML"
            id="username2-help"
            class="p-invalid"
            *ngIf="step1SelectedOption === 'option3' && newJobBluePrint.invalid && uploadFiles.length === 0"
            >Please upload your files.
          </label>
          <small class="inputDescription">
            Please include as many helpful documents as possible so your order can be processed in a timely manner.
            Valid documents may include:
            <ul>
              <li>Dimensioned Sketches (Slope or Ridge to Eave measurements included).</li>
              <li>Architectural Plans (Roof Plan, Dimensioned floor Plan and Elevations at a minimum).</li>
              <li>Aerial Photos (with a scale and all slopes included).</li>
              <li>Satellite Imagery (with a scale and all slopes included).</li>
            </ul>
          </small>
        </div>
      </div>
    </section>
    <alert
      *ngIf="isStep1Invalid()"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t set data source'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <alert
      *ngIf="showSearchResultMessageError === true"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t locate Address'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>

    <alert
      *ngIf="errorMessage"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Job name already exists'"
      [description]="'You already have another job with the same name. Please enter a new job name and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <div class="divider mt-4 mb-4"></div>
    <section class="footActionsCont spaced">
      <button pButton type="button" class="fullMobile" (click)="advanceToStep2()">
        Next
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
      <button pButton class="default outline fullMobile" type="button" (click)="changeStep(1, 0, true)">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Go back
      </button>
    </section>
  </step-card>

  <!-- Step 2 -->
  <step-card
    id="jobStep_2"
    number="3"
    label="Providers"
    [selected]="activeStep[2]"
    [textSelected]="'Choose data providers for this job:'"
    [completed]="completedStep[2]"
    [textCompleted]="getTextCompletedStep2()"
  >
    <section class="mb-4">
      <option-box
        [id]="'jobProvidersOption_ev'"
        [isRadio]="false"
        [value]="true"
        [disabled]="true"
        [checked]="step2SelectedOption.option1"
        image="assets/img/icons/source_ev.png"
        label="EagleView"
        [styleClass]="'borderBottom_0'"
        [formControl]="eagleViewControl"
      >
        <span
          >With only an address, you can obtain dimensions data from a location based on accurate aerial views
          provided by EagleView.</span
        >
        <span *ngIf="step1SelectedOption === 'option1'" class="optionInfoText">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          This provider is required by your selected data source (Address).
        </span>
        <span *ngIf="step1SelectedOption !== 'option1'" class="optionInfoText">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          Your selected data source is not compatible with this provider.
        </span>
      </option-box>
      <app-service-item
        [label]="'EagleView'"
        [description]="
          'With only an address, you can obtain dimensions data from a location based on accurate aerial views provided by EagleView.'
        "
        [disclaimer]="'At this time, EagleView provides data for buildings in the United States and Canada only.'"
        [logo]="'assets/img/icons/source_ev.png'"
        [account]="'account'"
        [isServiceAvailable]="true"
        [disabled]="step1SelectedOption !== 'option1'"
        [isCompact]="true"
        [isLogoVisible]="false"
        [isDemo]="true"
        [infoTooltip]="'Account and Billing'"
        [infoAlertTitle]="'Partner system Account and Billing'"
        [isInfoAlertVisible]="demoAlerts.eagleViewAccountAndBilling"
        (infoButtonClick)="toggleDemoAlert('eagleViewAccountAndBilling', $event)"
      >
      </app-service-item>
    </section>

    <section>
      <option-box
        [id]="'jobProvidersOption_rw'"
        [isRadio]="false"
        [checked]="step2SelectedOption.option2"
        [disabled]="true"
        image="assets/img/icons/source_rw.png"
        label="Roofing WRX"
        [styleClass]="'borderBottom_0'"
        [value]="true"
        [formControl]="roofingWrxControl"
      >
        <span
          >Get accurate 3D models and measurements from any type of drawing, like architect drawings, napkin sketches,
          and more.</span
        >
        <span *ngIf="step1SelectedOption !== 'option1'" class="optionInfoText">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          This provider is required by your selected data source.
        </span>
      </option-box>
      <app-service-item
        [label]="'Roofing WRX'"
        [logo]="'assets/img/icons/source_rw.png'"
        [account]="'account'"
        [isServiceAvailable]="isRoofingWRXActive === true"
        [isCompact]="true"
        [isLogoVisible]="false"
        [styleClass]="'borderBottom_0'"
        [isDemo]="true"
        [infoTooltip]="'Account and Billing'"
        [infoAlertTitle]="'Partner system Account and Billing'"
        [isInfoAlertVisible]="demoAlerts.roofingWRXAccountAndBilling"
        (infoButtonClick)="toggleDemoAlert('roofingWRXAccountAndBilling', $event)"
      >
      </app-service-item>
    </section>
    <alert
      *ngIf="step2HasError"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t set providers'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <div class="divider mt-0 mb-4"></div>
    <section class="footActionsCont spaced">
      <button pButton type="button" class="fullMobile" (click)="advanceToStep3()">
        Next
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
      <button pButton class="default outline fullMobile" type="button" (click)="changeStep(2, 1, true)">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Go back
      </button>
    </section>
  </step-card>

  <!-- Step 3 -->
  <step-card
    id="jobStep_3"
    number="4"
    label="Summary"
    [selected]="activeStep[3]"
    [textSelected]="'Please review your job and add any necessary information:'"
    [completed]="completedStep[3]"
    [textCompleted]="'Completed'"
  >
    <h2 class="srOnly">Summary</h2>

    <section>
      <div *ngIf="step1SelectedOption === 'option1'" class="flex justify-content-between align-items-center">
        <div class="inputCont">
          <label for="jobInput_code" class="label">Promotional code (optional)</label>
          <input pInputText type="text" id="jobInput_code" placeholder="Enter code" />
        </div>
        <app-info-button
          pTooltip="Promotional codes"
          (click)="toggleDemoAlert('promotionalCode', $event)"
        ></app-info-button>
      </div>
      <alert
        *ngIf="demoAlerts.promotionalCode && step1SelectedOption === 'option1'"
        color="main"
        [icon]="faCircleInfo"
        label="About promotional codes"
        description="Provide an EagleView promotional code here"
        styleClass="mb-4"
      >
      </alert>
      <div class="inputCont">
        <label for="jobInput_notes" class="label">Notes</label>
        <textarea
          pInputTextarea
          id="jobInput_notes"
          class="w-full"
          placeholder="Enter additional details"
          [(ngModel)]="order.jobComments"
        ></textarea>
      </div>
      <alert
        *ngIf="representedUser && representedUser.name !== null"
        color="main"
        [icon]="faUser"
        label="{{ representedUser.name }} will be the owner of this job"
        description="You're about to create a job in behalf of another user ({{
          representedUser.name
        }}). This user will see this job in their account and have full control of it. Please make sure the user you entered is correct and that you're not sharing sensitive information."
        styleClass="mb-4"
      >
      </alert>
    </section>
    <alert
      *ngIf="step4HasError"
      color="danger"
      [icon]="faTriangleExclamation"
      [label]="'Couldn\'t create job'"
      [description]="'Please check the marked fields and try again.'"
      [styleClass]="'mt-2'"
    >
    </alert>
    <div class="divider mb-4"></div>
    <section class="footActionsCont spaced">
      <button pButton type="button" class="success fullMobile" (click)="openFinalStepModal()">
        <fa-icon [icon]="faCheck"></fa-icon>
        Create job
      </button>
      <button pButton type="button" class="outline default fullMobile" (click)="changeStep(3, 2, true)">
        <fa-icon [icon]="faArrowLeft"></fa-icon>
        Go back
      </button>
    </section>
  </step-card>
</p-accordion>

<app-dialog-providers-step [isVisible]="currentStepModalOpen === 1"></app-dialog-providers-step>

<app-dialog-first-step
  [isVisible]="currentStepModalOpen === 2"
  [jobDataSource]="step1SelectedOption"
></app-dialog-first-step>

<app-dialog-second-step
  [isVisible]="currentStepModalOpen === 3"
  [jobDataSource]="step1SelectedOption"
></app-dialog-second-step>

<app-dialog-third-step
  [isVisible]="currentStepModalOpen === 4"
  [jobDataSource]="step1SelectedOption"
  [onContinueClick]="createJob.bind(this)"
></app-dialog-third-step>
