<section>
  <h1 class="font-light text-dark text-4xl mb-4">Discover Roofing Passport</h1>
  <div class="p-4 border-1 border-outline flex flex-column row-gap-4">
    <div class="flex align-items-center column-gap-8">
      <p class="text-dark text-sm max-w-32rem">
        The Sherwin-Williams Roofing Passport is built specifically for the metal residential & commercial roofing
        market. Our groundbreaking digital platform simplifies metal roof estimation and ordering for your projects.
      </p>
      <div class="flex column-gap-3">
        <img class="border-circle w-4rem" src="/assets/img/pictures/roofDetail_red.jpg" alt="random" />
        <img class="border-circle w-4rem" src="/assets/img/pictures/roofDetail_blue.jpg" alt="random" />
        <img class="border-circle w-4rem" src="/assets/img/pictures/roofDetail_green.jpg" alt="random" />
      </div>
    </div>
    <div class="px-4 pt-3 pb-4 bg-main-10 text-main-dark">
      <h4 class="font-semibold text-base mb-4">What you can expect in this demo:</h4>
      <ul class="list-none p-0 m-0 flex flex-column row-gap-4 text-sm">
        <li class="flex align-items-center column-gap-3">
          <fa-icon class="text-lg" [icon]="faHammer"></fa-icon>
          <p>Experience how the <span class="font-semibold">job creation process</span> works.</p>
        </li>
        <li class="flex align-items-center column-gap-3">
          <fa-icon class="text-lg" [icon]="faGears"></fa-icon>
          <p>
            Learn about all variables, <span class="font-semibold">integrations</span> and customization options
            available.
          </p>
        </li>
        <li class="flex align-items-center column-gap-3">
          <fa-icon class="text-lg" [icon]="faFile"></fa-icon>
          <p>Learn about the <span class="font-semibold">takeoff reports</span> generated by Roofing Passport.</p>
        </li>
      </ul>
    </div>
    <div class="flex justify-content-between align-items-end">
      <form *ngIf="DEMO_FEATURE_FLAGS.REQUEST_EMAIL" [formGroup]="startDemoForm" class="flex flex-column">
        <label class="text-dark text-sm mb-4" for="demoInput_email">Please enter your email address to begin:</label>
        <input
          pInputText
          type="email"
          id="demoInput_email"
          formControlName="email"
          placeholder="Email"
          class="w-25rem"
        />
        <small class="p-error" *ngIf="startDemoForm.get('email')?.invalid && startDemoForm.get('email')?.touched">
          Please enter a valid email address.
        </small>
      </form>
      <button
        pButton
        class="main ml-auto"
        [disabled]="startDemoForm.invalid && DEMO_FEATURE_FLAGS.REQUEST_EMAIL"
        (click)="handleBeginClick()"
      >
        Begin
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
    </div>
  </div>
</section>

<app-intro-modal></app-intro-modal>
