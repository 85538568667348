import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CoreModule } from "src/app/core/core.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PrimengLibraryModule } from "src/app/shared/libraries/primeng-library.module";
import { DialogService } from "primeng/dynamicdialog";
import { OrderService } from "src/app/shared/services/order.service";
import { RulesEngineService } from "src/app/shared/services/rules-engine.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { InviteUserComponent } from "../admin/invite-user/invite-user.component";
import { JobListComponent } from "./job-list/job-list.component";
import { NewJobComponent } from "./new-job/new-job.component";
import { CreatedSuccessfullyComponent } from "./created-successfully/created-successfully.component";
import { CreationFailedComponent } from "./creation-failed/creation-failed.component";
import { AddressFormComponent } from "./new-job/address-form/address-form.component";

@NgModule({
  declarations: [
    InviteUserComponent,
    JobListComponent,
    NewJobComponent,
    CreatedSuccessfullyComponent,
    CreationFailedComponent,
    AddressFormComponent,
  ],
  imports: [
    SharedModule,
    CoreModule,
    CommonModule,
    ReactiveFormsModule,
    PrimengLibraryModule,
    FormsModule,
    FlexLayoutModule,
  ],
  providers: [DialogService, OrderService, RulesEngineService],
})
export class OrdersModule {}
