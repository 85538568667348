import { AuthStatus } from "src/app/core/store/app.models";

export enum EXPECTED_OUTCOME {
  takeOffReport = 1,
  takeOffReportAndEditInSmartBuild = 2,
}

export type RoofingSystem = {
  label: string;
  value: number;
};

export type RoofingSystemInput = {
  defaultValue: number;
  description: string;
  qualifier: string;
  roofingSystemUserInputXRefId: number;
};

export type UserAuthorizations = {
  [key: string]: {
    email?: string;
    status: AuthStatus;
  };
};

export enum CreateOrderErrorId {
  FileValidation = 1,
  EVPromoCode = 2,
  Unknown = 3,
}

export type CreateOrderError = {
  detail: string;
  errorId: CreateOrderErrorId;
  instance: string;
  status: number;
  title: string;
  type: string;
};
