<p-header>
  <h2 class="font-light text-2xl">How data providers work</h2>
</p-header>

<div class="flex flex-column align-items-center mt-4">
  <div class="border-1 border-main py-3 px-4 flex align-items-center justify-content-center font-semibold">
    <img src="assets/img/icons/source_rw.png" alt="Roofing WRX" class="w-2rem" />
    <span class="text-sm ml-4">Roofing WRX</span>
  </div>
</div>

<p-divider></p-divider>
<p class="text-sm">
  Roofing WRX can generate accurate 3D models and roofing measurements based on a myriad of data sources like:
</p>
<ul>
  <li class="text-sm">Dimensioned Sketches (Slope or Ridge to Eave measurements included)</li>
  <li class="text-sm">Architectural Plans (Roof Plan, Dimensioned floor Plan and Elevations at a minimum)</li>
  <li class="text-sm">Aerial Photos (with a scale and all slopes included)</li>
  <li class="text-sm">Satellite Imagery (with a scale and all slopes included)</li>
</ul>
<div class="flex justify-content-between mt-4">
  <div class="ml-auto">
    <button pButton (click)="onClose()">Continue</button>
  </div>
</div>
