import { UserRole } from "./AppInitializationData";

export type AddressData = {
  street: string;
  city: string;
  state: string;
  postalCode: string;
};

export enum PayeeOption {
  Manufacturer = "Manufacturer",
  Company = "Company",
}

export interface ManufacturerInvitation {
  role?: UserRole;
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  inviterCompanyId?: number;
  contactPhoneNumber: string;
  companyName?: string;
  companyAddress?: AddressData;
  companyPhoneNumber?: string;
  payee?: PayeeOption;
  roofingWRXBranch?: string;
  SmartBuildDistributor?: string;
  isCompanyPaying?: boolean;
}
