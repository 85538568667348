import { AuthContext } from "../services/authentication.service";

export const ApiEndpoints = {
  // Initialization
  getAppInitializationData: "Init/GetAppInitializationData",
  getIPASignature: (overrideIpaKey?: string) => {
    const baseEndpoint = "Init/GetIPASignature";
    return overrideIpaKey !== undefined ? `${baseEndpoint}${overrideIpaKey}` : baseEndpoint;
  },

  // Authentication
  createInvitation: "Auth/CreateInvitation",
  completeRegistration: "Auth/CompleteRegistration/",
  rejectRegistration: (taskId?: string) => {
    const baseEndpoint = "Auth/RejectRegistration";
    return taskId !== undefined ? `${baseEndpoint}?taskId=${taskId}` : baseEndpoint;
  },
  completeInvitation: (companyId: number, accepted: boolean) => {
    return `Auth/CompleteInvitation?companyId=${companyId}&accepted=${accepted}`;
  },
  storeUserToken: (context: AuthContext) => `Auth/StoreUserToken/${context}`,
  clearTokens: "Auth/ClearTokens",

  //User
  getTasks: "User/GetTasks",
  completeTask: "User/CompleteTask",
  updateUser: "User/UpdateUser",
  getUsers: "User/GetUsers",
  getUser: (userProfileId?: number) => {
    const baseEndpoint = "User/GetUser";
    return userProfileId !== undefined ? `${baseEndpoint}?userProfileId=${userProfileId}` : baseEndpoint;
  },

  // Reporting
  getOrdersByFilter: "Reporting/GetOrdersByFilter",
  getOrderStatistics: "Reporting/GetOrderStatistics",
  GetOrderCountsByCompanyByUser: "Reporting/GetOrderCountsByCompanyByUser",
  GetOrderCountsByCompany: "Reporting/GetOrderCountsByCompany",
  //Files
  downloadUserReport: (manufacturerId?: number) => {
    const baseEndpoint = "File/DownloadUserReportCSV";
    return manufacturerId !== undefined ? `${baseEndpoint}/${manufacturerId}` : baseEndpoint;
  },
  getUserReportByManufacturerId: (manufacturerId?: number) => {
    const baseEndpoint = "File/GetUserReportByManufacturerId";
    return manufacturerId !== undefined ? `${baseEndpoint}/${manufacturerId}` : baseEndpoint;
  },
  getSmartBuildReport: (jobId?: number) => {
    const baseEndpoint = "File/GetSmartBuildReport";
    return jobId !== undefined ? `${baseEndpoint}?jobid=${jobId}` : baseEndpoint;
  },
  getEagleViewReportFile: (jobId?: number, fileTypeId?: number) => {
    const baseEndpoint = "File/GetEagleViewReportFile";
    return jobId !== undefined || fileTypeId !== undefined
      ? `${baseEndpoint}?jobId=${jobId}&fileTypeId=${fileTypeId}`
      : baseEndpoint;
  },
  getRoofingWRXReportFile: (jobId?: number, fileName?: string) => {
    const baseEndpoint = "File/GetRoofingWRXReportFile";
    return jobId !== undefined || fileName !== undefined
      ? `${baseEndpoint}?jobId=${jobId}&fileName=${fileName}`
      : baseEndpoint;
  },
  getEagleViewPremiumReport: (jobId?: number) => {
    const baseEndpoint = "File/GetEagleViewPremiumReport";
    return jobId !== undefined ? `${baseEndpoint}?jobid=${jobId}` : baseEndpoint;
  },
  uploadRoofingWRXJobFiles: (jobId?: number) => {
    const baseEndpoint = "File/UploadRoofingWRXJobFiles";
    return jobId !== undefined ? `${baseEndpoint}/${jobId}` : baseEndpoint;
  },
  downloadUserGuide: "File/GetRPUserGuide",
  downloadAdminUserGuide: "File/GetRPAdminUserGuide ",

  downloadJobFile: (params: { orderId?: string; fileName?: string }) => {
    const baseEndpoint = "File/GetJobFileByName";
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();
    return `${baseEndpoint}?${queryParams}`;
  },

  // Profile
  updateCompany: "Company/UpdateCompany",
  createCompany: "Company/CreateCompany",
  getAffiliatedCompanies: (userId: number) => `Company/GetAffiliatedCompanies?userId=${userId}`,
  validateProfile: "Profile/ValidateProfile/",
  resendRPInvitation: "Profile/ResendRPInvitation",
  sendSmartBuildOutageMessage: (context: AuthContext) => `Profile/SendSmartBuildOutageMessage/${context}`,
  updateRoofingWRXEnabled: "Profile/UpdateRoofingWRXEnabled",
  deactivateUser: (userProfileId?: number) => {
    const baseEndpoint = "Profile/DeactivateUser";
    return userProfileId !== undefined ? `${baseEndpoint}?userProfileId=${userProfileId}` : baseEndpoint;
  },
  updateTrainingStatus: "Profile/UpdateTrainingStatus",
  requestPartnerSystemAccess: (context: AuthContext) => `Profile/RequestPartnerSystemAccess/${context}`,
  getSelectCompany: "Profile/GetSelectCompany",

  // Order
  getProjectList: "Order/GetProjectList",
  getJobCountsByStatus: "Order/GetJobCountsByStatus",
  processNewOrder: "Order/ProcessNewOrder",
  nameValidation: "Order/NameValidation",
  getOrderDetails: (orderId?: string) => {
    const baseEndpoint = "Order/GetOrderDetails";
    return orderId !== undefined ? `${baseEndpoint}/${orderId}` : baseEndpoint;
  },
  updateRoofingWRXJobComments: (jobId?: number) => {
    const baseEndpoint = "Order/UpdateRoofingWRXJobComments";
    return jobId !== undefined ? `${baseEndpoint}/${jobId}` : baseEndpoint;
  },

  isEVPaymentSetupValid: "Order/IsEVPaymentSetupValid",

  // SmartBuildProject
  getSmartBuildToken: "SmartBuildProject/GetEditToken",

  // Demo
  registerDemoUserVisit: "Demo/RegisterDemoUserVisit",
  downloadTakeoffReport: "Demo/DownloadTakeOffReport",

  //Company
  getCompany: (companyId?: number) => {
    const baseEndpoint = "Company/GetCompany";
    return companyId !== undefined ? `${baseEndpoint}?companyId=${companyId}` : baseEndpoint;
  },
  getCompanies: "Company/GetCompanies",
  getCompanyNames: "Company/GetCompanyNames",
  getAffiliatedCompanyNames: "Company/GetAffiliatedCompanyNames",

  //Rules engine
  getRoofingSystems: (companyId?: number) => {
    const baseEndpoint = "RulesEngine/GetRoofingSystems";
    return companyId !== undefined ? `${baseEndpoint}?companyId=${companyId}` : baseEndpoint;
  },

  getRoofingSystemInputs: (roofingSystemId?: number) => {
    const baseEndpoint = "RulesEngine/GetRoofingSystemInputs";
    return roofingSystemId !== undefined ? `${baseEndpoint}?roofingSystemId=${roofingSystemId}` : baseEndpoint;
  },
};
