<p-header>
  <h2 class="font-light text-2xl">Processing jobs</h2>
</p-header>
<p class="text-sm mt-3 mb-4">
  This is a quick recap of what happens behind the scenes since the moment you create a new job:
</p>

<div
  class="relative flex flex-column align-items-center"
  [ngClass]="{ 'mb-7rem': !(currentStep === 1 || currentStep === 2), 'mb-5': currentStep === 1 || currentStep === 2 }"
>
  <div
    class="border-1 border-outline py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter"
    [ngClass]="{
      'animated-line': currentStep === 1 || currentStep === 2 || currentStep === 4,
      'border-main': currentStep === 1 || currentStep === 4,
      'border-outline': !(currentStep === 1 || currentStep === 4)
    }"
  >
    <fa-icon size="lg" class="text-main" [icon]="faCircleUser"></fa-icon>
    <span class="text-sm ml-4">You</span>
    <button *ngIf="currentStep === 1" pButton class="pointer-events-none bg-success border-success ml-4">
      <fa-icon [icon]="faCheck"></fa-icon>
      Create job
    </button>
  </div>
  <div
    class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter relative border-main"
    [ngClass]="{
      'animated-line': currentStep === 2 || currentStep === 3
    }"
  >
    <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-2rem" />
    <span class="text-sm ml-4">Roofing Passport</span>
  </div>
  <div class="relative w-full flex flex-column align-items-center">
    <div
      class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold border-outline"
      [ngClass]="{
        nonSelectedLeftService: !(currentStep === 1 || currentStep === 2),
        lineAfter: currentStep === 1 || currentStep === 2
      }"
    >
      <img src="assets/img/icons/source_ev.png" alt="EagleView" class="w-2rem" />
      <span class="text-sm ml-4">EagleView</span>
    </div>
    <div
      class="border-1 border-outline mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold"
      [ngClass]="{
        nonSelectedRightService: currentStep === 1 || currentStep === 2,
        'border-main': currentStep === 3,
        'border-outline': currentStep !== 3
      }"
    >
      <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="w-2rem" />
      <span class="text-sm ml-4">SmartBuild</span>
    </div>
  </div>
  <div
    class="border-1 border-outline mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold"
    [ngClass]="{
      nonSelectedJobLocation: !(currentStep === 1 || currentStep === 2)
    }"
  >
    <fa-icon size="lg" class="text-main" [icon]="faLocationDot"></fa-icon>
    <span class="text-sm ml-4">Job Location</span>
  </div>
</div>

<p-divider></p-divider>
<h4 class="my-3 font-semibold text-xs">Step {{ currentStep }}</h4>
<p class="text-sm" [ngSwitch]="currentStep">
  <ng-container *ngSwitchCase="1">
    Once you hit ”Create job”, Roofing Passport will begin the process of generating your takeoff report.
  </ng-container>
  <ng-container *ngSwitchCase="2">
    The <span class="font-semibold">location data</span> you entered is processed by Roofing Passport and sent to
    EagleView. EagleView then retrieves <span class="font-semibold">raw measurement data</span> from the specified
    address, which is delivered to SmartBuild via Roofing Passport. SmartBuild then converts the raw data into usable
    data for the takeoff report.
  </ng-container>
  <ng-container *ngSwitchCase="3">
    Finally, SmartBuild notifies Roofing Passport that the job is complete. You can retrieve your takeoff report within
    Roofing Passport or directly through SmartBuild.
  </ng-container>
  <ng-container *ngSwitchCase="4">
    This entire process usually takes about <span class="font-semibold">1-3 hours</span>, depending on the availability
    of data providers.
    <br />
    Also, please note this process varies depending of the data source and providers you choose.
  </ng-container>
</p>
<div class="flex justify-content-between mt-4">
  <button *ngIf="currentStep > 1" pButton class="default outline text-main border-main" (click)="previousStep()">
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    Back
  </button>
  <div class="ml-auto">
    <button *ngIf="currentStep !== 4" pButton class="default outline mr-3 text-main border-main" (click)="skip()">
      Skip
    </button>
    <button pButton (click)="nextStep()">
      {{ currentStep === 4 ? "Continue" : "Next" }}
      <fa-icon *ngIf="currentStep !== 4" [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</div>
