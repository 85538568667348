import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { DEMO_FEATURE_FLAGS } from "src/app/shared/constants/feature-flags";

const DEMO_TAKEOFF_REPORT_PATH = "/assets/files/demo-sample-takeoff-report.pdf";
const DEMO_TAKEOFF_REPORT_FILENAME = "demo-sample-takeoff-report.pdf";

@Component({
  selector: "app-create-job-success",
  templateUrl: "./create-job-success.component.html",
  styleUrls: ["./create-job-success.component.scss"],
})
export class CreateJobSuccessComponent implements OnInit {
  faCircleCheck = faCircleCheck;
  faDownload = faDownload;
  faCircleInfo = faCircleInfo;
  demoUserEmail: string;

  DEMO_FEATURE_FLAGS = DEMO_FEATURE_FLAGS;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (DEMO_FEATURE_FLAGS.REQUEST_EMAIL) {
      this.route.queryParams.subscribe((params) => {
        this.demoUserEmail = params["email"];

        if (!this.demoUserEmail) {
          this.router.navigate(["/demo"]);
          return;
        }
      });
    }
  }

  tryAgain(): void {
    const queryParams = DEMO_FEATURE_FLAGS.REQUEST_EMAIL ? { email: this.demoUserEmail } : {};
    this.router.navigate(["/demo/create-job"], { queryParams });
  }

  downloadTakeoffReport(): void {
    const link = document.createElement("a");
    link.href = DEMO_TAKEOFF_REPORT_PATH;
    link.download = DEMO_TAKEOFF_REPORT_FILENAME;
    link.click();
  }
}
