<div class="container container_steps">
  <div class="pageHeading pageHeading_end columnMobileOnly">
    <h1 class="pageTitle pageTitle_steps title2 my-0">
      {{ isInvited ? "Set up your account" : "Register" }}
    </h1>
    <app-steps *ngIf="shouldShowSteps()" [steps]="steps" [currentStep]="currentStep" class="mx-4 md:mx-3"></app-steps>
  </div>

  <div class="card card_border card_border_noMobile">
    <app-register-welcome
      *ngIf="currentStep === RegisterStep.WELCOME"
      [isInvited]="isInvited"
      (beginClicked)="goToStep(RegisterStep.TERMS)"
    ></app-register-welcome>
    <app-register-terms
      *ngIf="currentStep === RegisterStep.TERMS"
      (nextClicked)="goToStep(RegisterStep.INTEGRATIONS)"
    ></app-register-terms>
    <app-integrations
      *ngIf="currentStep === RegisterStep.INTEGRATIONS"
      (nextClicked)="goToStep(RegisterStep.SUMMARY)"
      (goBackClicked)="goToStep(RegisterStep.TERMS)"
    ></app-integrations>
    <app-summary
      *ngIf="currentStep === RegisterStep.SUMMARY"
      (nextClicked)="goToStep(RegisterStep.CONFIRM)"
      (goBackClicked)="goToStep(RegisterStep.INTEGRATIONS)"
    ></app-summary>
    <app-verification
      *ngIf="currentStep === RegisterStep.VERIFICATION"
      (goBackClicked)="goToStep(RegisterStep.SUMMARY)"
    ></app-verification>
    <app-confirm *ngIf="currentStep === RegisterStep.CONFIRM" [isInvited]="isInvited"></app-confirm>
  </div>
</div>
